//global
export const SET_CONFIG = "SET_CONFIG";

export const INITIALIZE_HANDLER = "INITIALIZE_HANDLER";
export const AJAX_HANDLER = "AJAX_HANDLER";
export const SUCCESS_HANDLER = "SUCCESS_HANDLER";
export const FAILURE_HANDLER = "FAILURE_HANDLER";

export const AJAX_VIEW_HANDLER = "AJAX_VIEW_HANDLER";
export const SUCCESS_VIEW = "SUCCESS_VIEW";
export const SUCCESS_DOWNLOAD = "SUCCESS_DOWNLOAD";
