import "../assets/header.css";

import React from "react";
import { Link } from "react-router-dom";
import {
  Layout,
  Menu,
  Avatar,
  Popover,
  Badge,
  List,
  Dropdown,
  Space,
} from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  BellOutlined,
  RightOutlined,
  LogoutOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import Cookies from "js-cookie";
import moment from "moment";

import { connect } from "react-redux";
import { setConfig, initHandler, ajaxViewHandler } from "../store/actions";

import Config from "../Config";

// import { logoutFirebase } from '../push-notification';

const { SubMenu } = Menu;
const { Header } = Layout;

class AdminHeader extends React.Component {
  config;

  constructor(props) {
    super(props);
    this.config = new Config();
  }

  UNSAFE_componentWillMount() {
    this.props.initHandler();
    // navigator.serviceWorker.removeEventListener("message", (message) => {});
  }

  componentDidMount() {
    // navigator.serviceWorker.addEventListener("message", (message) => {
    //     console.log('refreshing_new_data');
    // });
  }

  hide = () => {
    this.setState({
      visiblePopMenu: false,
    });
  };

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  toggle = () => {
    this.props.setConfig({
      collapsed: !this.props.collapsed,
    });
    // alert('collapsed'+JSON.stringify(this.props.collapsed))
  };

  prosesLogout = () => {
    // e.preventDefault();
    Cookies.remove(this.config.get_cookie_name());
    //Cookies.remove('aBdi_d3s4uth_temp');
    this.props.setConfig({
      isLogin: false,
      username: "",
      nama: "",
      id: "",
      pp: "",
      alamat: "",
      no_hp: "",
      layanan_id: [],
      layanan_str: [],
    });
    // logoutFirebase(this.props.desa);
  };

  handleMenuAtas = (e) => {
    if (e.key == "Logout") {
      Cookies.remove(this.config.get_cookie_name());
      //Cookies.remove('aBdi_d3s4uth_temp');
      this.props.setConfig({
        isLogin: false,
        username: "",
        nama: "",
        id: "",
        pp: "",
        alamat: "",
        no_hp: "",
        layanan_id: [],
        layanan_str: [],
      });
      // logoutFirebase(this.props.desa);
    }
  };

  render() {
    const items = [
      {
        key: "lgout",
        label: (
          <a
            href={"#"}
            onClick={(e) => {
              e.preventDefault();
              this.prosesLogout();
            }}
          >
            Logout
          </a>
        ),
        icon: <LogoutOutlined />,
      },
    ];
    return (
      <Header className={this.props.collapsed ? "header collapsed" : "header"}>
        {React.createElement(
          this.props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            className: "trigger",
            onClick: this.toggle,
          }
        )}
        <div className="rightContainer">
          <Dropdown
            menu={{
              items,
            }}
            // menu={[
            //   {
            //     key: "lgout",
            //     label: (
            //       <a
            //         href={"#"}
            //         onClick={(e) => {
            //           e.preventDefault();
            //           this.prosesLogout();
            //         }}
            //       >
            //         Logout
            //       </a>
            //     ),
            //     icon: <LogoutOutlined />
            //   },
            // ]}
          >
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <div>
                <span className="menu-atas">
                  {this.props.nama != null && this.props.nama != ""
                    ? this.props.nama
                    : this.props.username}
                </span>
                <Avatar
                  style={{ marginRight: 20 }}
                  size="large"
                  src={
                    this.props.pp != "" && this.props.pp != null
                      ? this.props.pp
                      : process.env.PUBLIC_URL + "/avatar.png"
                  }
                />
              </div>
            </a>
          </Dropdown>
        </div>
      </Header>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  initHandler,
  setConfig,
  ajaxViewHandler,
})(AdminHeader);
