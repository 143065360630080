import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import Cookies from 'js-cookie';
import Config from './Config';

let config = new Config();

export const PrivateRoute = ({component: Component, ...rest}) => (
	<Route {...rest} render={props => (
			Cookies.get(config.get_cookie_name()) 
				? <Component {...props} />
				: <Redirect to={{ pathname: "/auth", state: { from: props.location } }} />
		)} />
);