import React, { PureComponent } from "react";
import { Redirect, Link } from "react-router-dom";
import { withRouter } from "react-router";
import {
  Button,
  Row,
  Col,
  Input,
  Layout,
  Form,
  Alert,
  Modal,
  message,
  Switch,
  Tabs,
  notification,
  Spin,
  Pagination,
  Tag,
  Breadcrumb,
  Card,
  Space,
  Table,
  Popconfirm,
  Skeleton,
  Select,
  InputNumber,
} from "antd";
import {
  TeamOutlined,
  SearchOutlined,
  PlusOutlined,
  FormOutlined,
  CloseSquareOutlined,
  ExclamationCircleOutlined,
  DiffOutlined,
  RedoOutlined,
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
  SaveOutlined,
} from "@ant-design/icons";
// import "@ant-design/compatible/assets/index.css";
import Config from "../Config";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";
import { setConfig, ajaxHandler, ajaxViewHandler } from "../store/actions";

import moment from "moment";

import axios from "axios";
import Cookies from "js-cookie";

import { pertimbangan1 } from "../models/ppbr/strategi_pengawasan_items/pertimbangan1";
import { pertimbangan2 } from "../models/ppbr/strategi_pengawasan_items/pertimbangan2";
import { pertimbangan3 } from "../models/ppbr/strategi_pengawasan_items/pertimbangan3";
import { pertimbangan4 } from "../models/ppbr/strategi_pengawasan_items/pertimbangan4";
import { pertimbangan5 } from "../models/ppbr/strategi_pengawasan_items/pertimbangan5";
import { pertimbangan6 } from "../models/ppbr/strategi_pengawasan_items/pertimbangan6";
import { pertimbangan7 } from "../models/ppbr/strategi_pengawasan_items/pertimbangan7";
import { pertimbangan8 } from "../models/ppbr/strategi_pengawasan_items/pertimbangan8";

import Pertimbangan1 from "./resiko_are_pengawasan_items/Pertimbangan1.jsx";

class ResikoAreaPengawasan extends PureComponent {
  config;
  timeoutGetQr;
  timeOutPushQr;
  formRef = React.createRef();
  formRefKeterangan = React.createRef();
  focusComponent = "";
  pk = "";

  constructor(props) {
    super(props);
    this.config = new Config();
    
    this.state = {
      
    };
  }

  getTabItems = () => {
    return [
      {
        key: "1",
        label: "PERTIMBANGAN 1",
        children: (
          <Pertimbangan1
            models={pertimbangan1.model}
            configClass={pertimbangan1.router[0]}
            //{...props}
          /> 
        ),
      },
      {
        key: "2",
        label: "PERTIMBANGAN 2",
        children: (
          <Pertimbangan1
            models={pertimbangan2.model}
            configClass={pertimbangan2.router[0]}
            //{...props}
          />
        )
      },
      {
        key: "3",
        label: "PERTIMBANGAN 3",
        children: (
          <Pertimbangan1
            models={pertimbangan3.model}
            configClass={pertimbangan3.router[0]}
            //{...props}
          />
        )
      },
      {
        key: "4",
        label: "PERTIMBANGAN 4",
        children: (
          <Pertimbangan1
            models={pertimbangan4.model}
            configClass={pertimbangan4.router[0]}
            //{...props}
          />
        )
      },
      {
        key: "5",
        label: "PERTIMBANGAN 5",
        children: (
          <Pertimbangan1
            models={pertimbangan5.model}
            configClass={pertimbangan5.router[0]}
            //{...props}
          />
        )
      },
      {
        key: "6",
        label: "PERTIMBANGAN 6",
        children: (
          <Pertimbangan1
            models={pertimbangan6.model}
            configClass={pertimbangan6.router[0]}
            //{...props}
          />
        )
      },
      {
        key: "7",
        label: "PERTIMBANGAN 7",
        children: (
          <Pertimbangan1
            models={pertimbangan7.model}
            configClass={pertimbangan7.router[0]}
            //{...props}
          />
        )
      },
      {
        key: "8",
        label: "PERTIMBANGAN 8",
        children: (
          <Pertimbangan1
            models={pertimbangan8.model}
            configClass={pertimbangan8.router[0]}
            //{...props}
          />
        )
      },
    ];
  };

 

  render() {
    return (
      <>
        
                  <Tabs
                    defaultActiveKey="1"
                    items={this.getTabItems()}
                    // onChange={onChange}
                  />
        
      
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  setConfig,
  ajaxHandler,
  ajaxViewHandler,
})(withRouter(ResikoAreaPengawasan));
