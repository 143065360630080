export const tingkat_resiko = {
  router: [
    {
      name: "TINGKAT RESIKO",
      key: "tingkat_resiko",
      path: "/admin/master/tingkat-resiko",
      apiUrl: {
        data: "master/api/tingkat/resiko/",
        preview: "master/api/tingkat/resiko/",
      },
      breadCrumb: [
        {
          label: "MASTER",
          link: "#",
          active: false,
        },
        {
          label: "TINGKAT RESIKO",
          link: "#",
          active: true,
        },
      ],
      component: "datatable1",
    },
  ],
  model: [
    {
      name: "id",
      isPk: true,
      isForm: false,
      isTable: false,
    },
    {
      name: "nama",
      label: "Nama",
      type: "text",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
  ],
};
