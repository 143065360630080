import React from 'react';
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Avatar,
	message,
	Result
} from 'antd';
import {
  HomeOutlined,
  TeamOutlined,
  HeartOutlined,
  MailOutlined,
  SnippetsOutlined
} from '@ant-design/icons';

import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../store/actions";

import Config from '../Config';

import Chart from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "../vars/chart.jsx";

const mode = "light";
const fonts = {
  base: "Open Sans"
};

const configClass = {
  apiUrl: {
    dashboard: ""
  }
}

class Forbidden extends React.Component {
	constructor(props){
		super(props);
	}

	componentDidMount(){
		// this.getData();
	}

	render() {
		return(
			<Result
			    status="403"
			    title="403"
			    subTitle="Sorry, you are not authorized to access this page."
			/>
		);
	}
}

const mapStateToProps = function(state) {
	return state.configReducers
}

export default connect(
		mapStateToProps,
		{
			initHandler,
			setConfig,
			ajaxViewHandler,
			ajaxHandler
		}
	)(Forbidden);