import React, { PureComponent } from "react";
import { Redirect, Link } from "react-router-dom";
import { withRouter } from "react-router";
import {
  Button,
  Row,
  Col,
  Input,
  Layout,
  Form,
  Alert,
  Modal,
  message,
  Switch,
  Tabs,
  notification,
  Spin,
  Pagination,
  Tag,
  Breadcrumb,
  Card,
  Space,
  Table,
  Popconfirm,
  Skeleton,
  Select,
  InputNumber,
  Radio,
  Checkbox,
  DatePicker,
  Divider,
} from "antd";
import {
  TeamOutlined,
  SearchOutlined,
  PlusOutlined,
  FormOutlined,
  CloseSquareOutlined,
  ExclamationCircleOutlined,
  DiffOutlined,
  RedoOutlined,
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
  SaveOutlined,
  BookOutlined
} from "@ant-design/icons";
// import "@ant-design/compatible/assets/index.css";
import Config from "../../Config";
import { Helmet } from "react-helmet";

import { Editor } from "@tinymce/tinymce-react";

import { connect } from "react-redux";
import { setConfig, ajaxHandler, ajaxViewHandler } from "../../store/actions";

import moment from "moment";

import axios from "axios";
import Cookies from "js-cookie";

const configClass = {
  apiUrl: {
    sasaran_audit: "ppbr/api/surat/tugas/",
    template: "master/api/langkah/audit/",
    clone: "persiapan/api/program/kerja/audit/",
    disusun_oleh: "pengguna/api/pengguna/",
    disetujui_oleh: "pengguna/api/pengguna/",
    mengetahui: "pengguna/api/pengguna/",
    langkah_audit: "master/api/langkah/audit/",
    dilaksanakan_oleh: "pengguna/api/pengguna/",
    preview: "persiapan/api/program/kerja/audit/",
  }
}
class FormProgramKerjaAudit extends PureComponent {
  config;
  timeoutGetQr;
  timeOutPushQr;
  formRef = React.createRef();
  formRefModal = React.createRef();
  focusComponent = "";
  pk = "";

  constructor(props) {
    super(props);
    this.config = new Config();
    this.state = {
      tambah: false,
      aksi: "tambah",
      method: "post",
      langkahAuditDipilih: null,
      dataLangkahAudit: [],
      paramsLangkahAudit: [],
      dataClone: null,
      errMsg: "",
      errTitle: "",
      isSuccess: false,
    };
  }

  async getMasterList(stateName, url, isNext = false) {
    this.setState({
      ["loading" + stateName]: !isNext,
      ["loadingNext" + stateName]: isNext,
      ["loadingSearch" + stateName]: isNext,
    });
    this.props
      .ajaxViewHandler("get", url)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (data.count != undefined) {
            let newState = null;
            if (
              this.state["data" + stateName] == undefined ||
              this.state["data" + stateName] == null
            ) {
              newState = data;
            } else {
              if (data.previous == null) {
                //first data
                newState = data;
              } else {
                const oldState = this.state["data" + stateName];
                newState = {
                  count: data.count,
                  next: data.next,
                  previous: data.previous,
                  results: [...oldState.results, ...data.results],
                };
              }
            }
            // console.log('state_name', destination.stateDestination);
            // console.log('state_value', newState);
            this.setState(
              {
                ["data" + stateName]: newState,
              },
              () => {
                if (stateName == "jenis_surat") {
                  let temp = [];
                  data.results.map((item, idx) => {
                    temp = [
                      ...temp,
                      {
                        value: item.id,
                        label: item.nama,
                      },
                    ];
                  });
                  this.setState({
                    ["data" + stateName]: temp,
                  });
                }
              }
            );
          } else if (data.results != undefined) {
            this.setState({
              ["data" + stateName]: data.results,
            });
          } else {
            if (Array.isArray(data)) {
              this.setState({
                ["data" + stateName]: data,
              });
            } else {
              this.setState({
                ["data" + stateName]: [data],
              });
            }
          }
        } else {
          console.log(data);
        }
        this.setState({
          ["loading" + stateName]: false,
          ["loadingNext" + stateName]: false,
          ["loadingSearch" + stateName]: false,
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          ["loading" + stateName]: false,
          ["loadingNext" + stateName]: false,
          ["loadingSearch" + stateName]: false,
        });
      });
  }

  handleChangeLangkah(value, nama, id, itemSelected) {
    let temp = this.state.paramsLangkahAudit;
    temp.map((item, idx) => {
      if (item.id == id) {
        temp[idx].prosedur_audit.map((items, idxAudit) => {
          if (temp[idx].prosedur_audit[idxAudit].id == itemSelected.id) {
            temp[idx].prosedur_audit[idxAudit][nama] = value
          }
        });
      }
    });
    this.setState({
      paramsLangkahAudit: temp
    });
    console.log(this.state.paramsLangkahAudit);
  }

  async getDetailLangkahAudit(id) {
    this.setState({
      ["loadingDetailLangkahAudit"]: true,
    });
    this.props
      .ajaxViewHandler("get", configClass.apiUrl.langkah_audit + id)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          let temp = this.state.dataLangkahAudit;
          temp = [...temp, data];
          console.log(temp)
          this.setState({
            ["dataLangkahAudit"]: temp
          });


          let temp_langkah = this.state.paramsLangkahAudit;
          if (data != null) {
            let temp_prosedur_audit = [];
            data.prosedur_audit.map((item, idx) => {
              temp_prosedur_audit = [...temp_prosedur_audit, {
                id: item.id,
                rencana_anggota: 0,
                rencana_hari: 0,
                realisasi_anggota: 0,
                realisasi_hari: 0,
                ref_kka: "",
                keterangan: ""
              }];
            });
            temp_langkah = [...temp_langkah, {
              id: data.id,
              prosedur_audit: temp_prosedur_audit
            }];
            this.setState({
              paramsLangkahAudit: temp_langkah
            });
          }

        } else {
          console.log(data);
        }
        this.setState({
          ["loadingDetailLangkahAudit"]: false,
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          ["loadingDetailLangkahAudit"]: false,
        });
      });
  }

  generateYears = () => {
    var start = 2020;
    var end = new Date().getFullYear() + 3;
    var items = [];
    for (var i = start; i <= end; i++) {
      items = [
        ...items,
        {
          value: i,
          label: i,
        },
      ];
    }
    this.setState(
      {
        yearItems: items,
      },
      () => {
        // console.log('year', this.state.yearItems)
        this.formRef.current.setFieldsValue({
          tahun: new Date().getFullYear(),
        });
      }
    );
  };

  generateSelectAsync = (stateName, url, label, value, idxAnggota = -1, placeholder = "", auditNama = "", auditId = "", auditItem = "") => {
    return this.state["loading" + stateName] ? (
      <Skeleton active paragraph={false} />
    ) : (
      <Select
        style={{ minWidth: 200 }}
        dropdownMatchSelectWidth={true}
        id={stateName}
        showSearch
        placeholder={placeholder == "" ? "pilih" : placeholder}
        optionFilterProp="children"
        allowClear
        onSearch={(val) => {
          let tempUrl = "";
          if (url.includes("?")) {
            tempUrl = url + "&search=" + val;
          } else {
            tempUrl = url + "?search=" + val;
          }
          this.getMasterList(stateName, tempUrl, false);
        }}
        onPopupScroll={(e) => {
          e.persist();
          let target = e.target;
          if (target.scrollTop + target.offsetHeight >= target.scrollHeight) {
            if (
              this.state["data" + stateName].next != undefined &&
              this.state["data" + stateName].next != null
            ) {
              this.getMasterList(
                stateName,
                this.state["data" + stateName].next,
                true
              );
            }
          }
        }}
        onChange={(val) => {
          /*
          if (stateName == "pegawai" || stateName == "jabatan_tugas") {
            let temp = [...this.state.anggota];
            temp[idxAnggota][stateName] = val;
            this.setState({
              anggota: temp,
            });
          } else if (stateName == "langkah_audit") {
            this.setState({
              langkahAuditDipilih: val
            });
          }
          */
          if (stateName == "dilaksanakan_oleh" || stateName == "direncanakan_oleh") {
            this.handleChangeLangkah(val, auditNama, auditId, auditItem);
          } else if (stateName == "langkah_audit") {
            this.setState({
              langkahAuditDipilih: val
            });
          }
        }}
      >
        {this.state["loadingSearch" + stateName] && (
          <Skeleton active paragraph={false} />
        )}
        {this.state["data" + stateName] != undefined &&
          this.state["data" + stateName] != null && (
            <>
              {this.state["data" + stateName].results != undefined
                ? this.state["data" + stateName].results.map((item) => (
                  <Select.Option value={item[value]}>
                    {item[label]}
                  </Select.Option>
                ))
                : this.state["data" + stateName].map((item) => (
                  <Select.Option value={item[value]}>
                    {item[label]}
                  </Select.Option>
                ))}
            </>
          )}
        {this.state["loadingNext" + stateName] && (
          <Skeleton active paragraph={false} />
        )}
      </Select>
    );
  };

  setFile(e, name, id) {
    this.setState(
      {
        [name + "_file"]: e.target.files[0],
      },
      () => {
        // console.log(this.state[name+"_file"])
        let params = new FormData();
        params.append("lampiran", this.state[name + "_file"], this.state[name + "_file"].name)
        this.doUploadLampiran(params, id);
      }
    );
  }

  showReview(row) {
    this.setState(
      {
        suratTugas: row,
        openReview: true,
      },
      () => {
      }
    );
  }

  showInsert(row) {
    let tempAnggota = [];
    if (row.anggota != undefined) {
      row.anggota.map((item, idx) => {
        tempAnggota = [...tempAnggota, {
          label: item.pegawai_nama == undefined ? "anggota->pegawai_nama" : item.pegawai_nama,
          value: item.pegawai,
        }];
      });
    }
    this.setState(
      {
        suratTugas: row,
        open: true,
        aksi: "tambah",
        idLama: "",
        method: "post",
        editingKey: "",
        anggota: tempAnggota,
        hariAnggota: row.anggota,
      },
      () => {
        this.formRefModal.current.resetFields();
        this.formRefModal.current.setFieldsValue({
          nomor_surat: row.surat_tugas_nomor
        });
      }
    );
  }

  showEdit = (record) => {
    this.formRef.current.resetFields();

    let tempData = this.state.dataSource;

    this.setState(
      {
        prevSource: tempData,
        editingKey: record.id,
      },
      () => {
        this.preview(record.id);
      }
    );
    // this.formRef.current.setFieldsValue({
    //   nama: '',
    //   ...record,
    // });
    // this.setState({
    //   editingKey: record.id
    // }, console.log('editingkey', record.id));
  };

  async preview(id) {
    this.setState({
      loadingData: true,
      errTitle: "",
      errMsg: "",
    });

    this.props
      .ajaxViewHandler("get", this.props.configClass.apiUrl.preview + id)
      .then(() => {
        // setTimeout(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          // this.formRef.current.setFieldsValue(data);
          for (var key in data) {
            const dateRegex = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/;
            const dateTimeRegex =
              /^[0-9]{4}\-[0-9]{2}\-[0-9]{2} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;
            if (dateRegex.test(data[key])) {
              //jika format tanggal
              this.formRef.current.setFieldsValue({
                [key + "_picker"]: moment(new Date(data[key]), "DD-MM-YYYY"),
                [key]: moment(new Date(data[key]), "DD-MM-YYYY"),
              });
            } else if (dateTimeRegex.test(data[key])) {
              //jika format tanggal dan jam
              this.formRef.current.setFieldsValue({
                [key + "_picker"]: moment(
                  new Date(data[key]),
                  "DD-MM-YYYY HH:mm:ss"
                ),
                [key]: moment(new Date(data[key]), "DD-MM-YYYY HH:mm:ss"),
              });
            } else {
              if (key == "body") {
                this.setState({
                  artikelString: data[key],
                });
                this.formRef.current.setFieldsValue({
                  content_editor: data[key],
                  body_editor: data[key],
                });
              } else if (key == "tag") {
                let temp = [];
                data[key].map((item) => {
                  temp = [
                    ...temp,
                    {
                      value: item.id,
                      label: item.nama,
                      key: item.id,
                    },
                  ];
                });
                this.formRef.current.setFieldsValue({
                  tags: temp,
                });
              } else if (key == "image" || key == "dokumen") {
                // let temp = [];
                // let tempGb = [];
                // data[key].map((item) => {
                //   temp = [
                //     ...temp,
                //     {
                //       id: item.id,
                //       name: item.name,
                //     },
                //   ];
                //   tempGb = [
                //     ...tempGb,
                //     {
                //       id: item.id,
                //       name: item.name,
                //       path: item.path,
                //     },
                //   ];
                // });
                // this.setState({
                //   gbr_post: temp,
                //   gambar_edit: tempGb,
                // });
              } else {
                this.formRef.current.setFieldsValue({
                  [key]: data[key],
                });
              }
            }

            // if (key == "icon") {
            //   this.setState({
            //     preview_gambar: data[key],
            //   });
            // }
          }
          message.destroy();
          this.setState(
            {
              aksi: "edit",
              method: "put",
              idLama: data.id + "/",
              loadingData: false,
            },
            () => {
              if (this.focusComponent != null && this.focusComponent != "") {
                document.getElementById(this.focusComponent).focus();
              }
            }
          );
        } else {
          const errTitle = "error preview data lama";
          this.setState({
            errTitle: errTitle,
            errMsg: JSON.stringify(data),
            loadingData: false,
          });
        }
        // }, 500);
      })
      .catch((response) => {
        this.setState({
          errTitle: "gagal get data",
          errMsg: JSON.stringify(response),
          loadingData: false,
        });
      });
  }

  // editableCell = (
  //   editing,
  //   dataIndex,
  //   title,
  //   inputType,
  //   record,
  //   index,
  //   children,
  //   ...restProps
  // ) => {
  //   const inputNode = <Input />;
  //   return (
  //     <td>
  //       {editing != undefined && editing ? (
  //         <Form.Item
  //           name={dataIndex}
  //           style={{
  //             margin: 0,
  //           }}
  //           rules={[
  //             {
  //               required: true,
  //               message: `Please Input ${title}!`,
  //             },
  //           ]}
  //         >
  //           {inputNode}
  //         </Form.Item>
  //       ) : (
  //         children
  //       )}
  //     </td>
  //   );
  // };

  componentDidMount() {
    this.getMasterList("sasaran_audit", configClass.apiUrl.sasaran_audit);
    this.getMasterList("template", configClass.apiUrl.template);
    this.getMasterList("disusun_oleh", configClass.apiUrl.disusun_oleh);
    this.getMasterList("disetujui_oleh", configClass.apiUrl.disetujui_oleh);
    this.getMasterList("mengetahui", configClass.apiUrl.mengetahui);
    this.getMasterList("langkah_audit", configClass.apiUrl.langkah_audit);

    this.getMasterList("direncanakan_oleh", configClass.apiUrl.dilaksanakan_oleh);
    this.getMasterList("dilaksanakan_oleh", configClass.apiUrl.dilaksanakan_oleh);

    if (this.props.match.params.id != undefined) {
      this.preview(this.props.match.params.id);
    }
  }

  setVal(e, stateName) {
    this.setState({
      [stateName]: e.target.value,
    });
  }


  async postData(datas, id) {
    this.setState({
      loadingData: true,
      errTitle: "",
      errMsg: "",
      dataClone: null,
    });
    // message.loading({ content: 'Memproses...', key: 'toast' });

    this.props
      .ajaxHandler(
        "post",
        configClass.apiUrl.preview + id,
        datas,
        false,
        true
      )
      .then((res) => {
        // setTimeout(() => {
        this.setState({
          loadingData: false,
        });

        if (res.type == "SUCCESS_HANDLER") {
          const data = res.payload.data;
          
          notification.success({
            message: "success",
            description: "berhasil memproses.",
            placement: "bottomRight",
          });

          this.setState({
            dataClone: data,
            isSuccess: true
          });

        } else {
          notification.error({
            message: "gagal",
            description: "gagal memproses.",
            placement: "bottomRight",
          });
          const errTitle = "gagal clone template";
          // console.log("tes", res);
          this.setState({
            errTitle: errTitle,
            errMsg:
              res.error.response.data.message != undefined
                ? JSON.stringify(res.error.response.data.message)
                : JSON.stringify(res.error.response.data),
          });
        }
        // }, 0);
      })
      .catch((response) => {
        notification.error({
          message: "gagal",
          description: "gagal menyimpan.",
          placement: "bottomRight",
        });
        this.setState({
          loadingData: false,
          errTitle: "gagal menyimpan",
          errMsg: JSON.stringify(response),
        });
      });
  }


  handleKeyUp = (event) => {
    // Enter
    if (event.keyCode === 13) {
      this.formRef.current.submit();
    }
  };

  handleSubmit = (values) => {
    var params = {};
    for (var key in values) {
      if (values[key] == undefined) {
        params = { ...params, [key]: "" };
      } else {
        // datas.append(key, values[key].toString());
        params = { ...params, [key]: values[key].toString() };
      }
    }
    params = { ...params, langkah_audit: this.state.paramsLangkahAudit };

    this.postData(params, "");
  };

  render() {
    if(this.state.isSuccess){
      return <Redirect to="/admin/persiapan/pka" />
    }

    return (
      <>
        {/*<!-- BEGIN: Content-->*/}
        <Breadcrumb>
          {this.props.breadCrumb.map((item, idx) => (
            <Breadcrumb.Item key={"bread" + idx}>{item.label}</Breadcrumb.Item>
          ))}
        </Breadcrumb>
        <div className="card">
          <Row className="isi">
            <Col span={24}>
              <Card
                className="konten"
                title={
                  <Row>
                    <Col lg={{ span: 20 }} xs={{ span: 24 }}>
                      <span className="konten-judul">
                        {this.props.configClass.name}
                      </span>
                    </Col>
                    <Col
                      lg={{ span: 4 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      style={{ textAlign: "right" }}
                    >
                      <Space>
                        <Button
                          loading={this.state.loadingData}
                          type="default"
                          size="small"
                          onClick={() => this.getData()}
                        >
                          <RedoOutlined />
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                }
              >
                {this.state.errMsg != "" &&
                  <Alert
                    message={this.state.errMsg}
                    type="error"
                    showIcon />
                }

                <Form
                  name="finput"
                  // onKeyUp={this.handleKeyUp}
                  onFinish={this.handleSubmit}
                  autoComplete="off"
                  ref={this.formRef}
                  layout="vertical"
                >
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Form.Item
                        label="NOMOR SURAT"
                        name="nomor_surat"
                      >
                        <Input placeholder="input nomor surat" />
                      </Form.Item>
                    </Col>
                    <Col span={12}></Col>
                    <Col span={12}>
                      <Form.Item
                        label="SASARAN AUDIT"
                        name="sasaran_audit"
                      >
                        {this.generateSelectAsync(
                          "sasaran_audit",
                          configClass.apiUrl.template,
                          "nomor",
                          "id"
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={12}></Col>
                    {/*<Col span={12}>
                      <Form.Item
                        label="TEMPLATE LANGKAH AUDIT"
                        name="template"
                      >
                        {this.generateSelectAsync(
                          "template",
                          configClass.apiUrl.template,
                          "nama",
                          "id"
                        )}
                      </Form.Item>
                    </Col>*/}
                    <Col span={24}>
                      <Row style={{ border: "1px solid #ccc", padding: 10, borderRadius: 10 }}>
                        <Col span={24}>
                          <Space>
                            <label>
                              Pilih Langkah Audit
                            </label>
                            {this.generateSelectAsync(
                              "langkah_audit",
                              configClass.apiUrl.langkah_audit,
                              "nama",
                              "id"
                            )}
                            <Button
                              type="primary"
                              success
                              htmlType="button"
                              onClick={(e) => {
                                if (this.state.langkahAuditDipilih != null) {
                                  this.getDetailLangkahAudit(this.state.langkahAuditDipilih);
                                }
                              }}
                            >
                              Tambahkan
                            </Button>
                          </Space>
                        </Col>
                        <Col span={24}>
                          <Divider />
                        </Col>
                        <Col span={24}>
                          {this.state.dataLangkahAudit.length > 0 &&
                            <div style={{ overflowX: "auto" }}>
                              <table style={{ minWidth: "100%" }}>
                                <tr>
                                  <td>NO</td>
                                  <td>URAIAN</td>
                                  <td style={{ width: "25%" }}>RENCANA</td>
                                  <td style={{ width: "45%" }}>REALISASI</td>
                                </tr>
                                {this.state.dataLangkahAudit.map((item, idx) => (
                                  <>
                                    <tr>
                                      <td valign="top" rowSpan={item.prosedur_audit.length + 1}>{item.kode}</td>
                                      <td>
                                        <div style={{ fontWeight: "bold" }}>{item.judul}</div><br />
                                        {"Tujuan: " + item.tujuan}<br />
                                        <div style={{ fontWeight: "bold" }}>Prosedur Audit</div>
                                      </td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                    {item.prosedur_audit.map((itemProsedur, idxProsedur) => (
                                      <tr>
                                        <td valign="top">
                                          {(idxProsedur + 1) + ". " + itemProsedur.nama}
                                        </td>
                                        <td valign="top" style={{ whiteSpace: "no-wrap" }}>
                                          {this.generateSelectAsync(
                                            "direncanakan_oleh",
                                            configClass.apiUrl.dilaksanakan_oleh,
                                            "nama",
                                            "id",
                                            -1,
                                            "Dilaksanakan Oleh",
                                            "rencana_anggota",
                                            item.id,
                                            itemProsedur
                                          )}
                                          <Input
                                            placeholder="hari tugas"
                                            style={{ display: "inline", width: 100 }}
                                            onChange={(e) => {
                                              this.handleChangeLangkah(e.target.value, "rencana_hari", item.id, itemProsedur)
                                            }}
                                          />
                                        </td>
                                        <td valign="top" style={{ whiteSpace: "no-wrap" }}>
                                          {this.generateSelectAsync(
                                            "dilaksanakan_oleh",
                                            configClass.apiUrl.dilaksanakan_oleh,
                                            "nama",
                                            "id",
                                            -1,
                                            "Dilaksanakan Oleh",
                                            "realisasi_anggota",
                                            item.id,
                                            itemProsedur
                                          )}
                                          <Input
                                            placeholder="hari tugas"
                                            style={{ display: "inline", width: 100 }}
                                            onChange={(e) => {
                                              this.handleChangeLangkah(e.target.value, "realisasi_hari", item.id, itemProsedur)
                                            }}
                                          />


                                          <Input
                                            placeholder="ref no kka"
                                            style={{ display: "inline", width: 100 }}
                                            onChange={(e) => {
                                              this.handleChangeLangkah(e.target.value, "ref_kka", item.id, itemProsedur)
                                            }}
                                          />
                                          <Input
                                            placeholder="keterangan"
                                            style={{ display: "inline", width: 200 }}
                                            onChange={(e) => {
                                              this.handleChangeLangkah(e.target.value, "keterangan", item.id, itemProsedur)
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                ))}
                                {this.state.loadingDetailLangkahAudit &&
                                  <Spin />
                                }
                              </table>
                            </div>
                          }
                        </Col>
                      </Row>
                    </Col>
                    {this.state.dataLangkahAudit.length > 0 &&
                      <>
                        <Col span={12}>
                          <Form.Item
                            name="tanggal_pka"
                            label="TANGGAL PKA"
                          >
                            <DatePicker
                              placeholder="dd-mm-yyyy"
                              format="DD-MM-YYYY"
                              style={{ width: "100%" }}
                              onChange={(date, dateString) => {

                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="disusun_oleh"
                            label="DISUSUN OLEH"
                          >
                            {this.generateSelectAsync(
                              "disusun_oleh",
                              configClass.apiUrl.disusun_oleh,
                              "nama",
                              "id"
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="disetujui_oleh"
                            label="DISETUJUI OLEH"
                          >
                            {this.generateSelectAsync(
                              "disetujui_oleh",
                              configClass.apiUrl.disetujui_oleh,
                              "nama",
                              "id"
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="mengetahui"
                            label="MENGETAHUI"
                          >
                            {this.generateSelectAsync(
                              "mengetahui",
                              configClass.apiUrl.mengetahui,
                              "nama",
                              "id"
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Space>

                            <Button htmlType="submit" type="primary" title="simpan">
                              <SaveOutlined /> Simpan
                            </Button>
                            <Link to="/admin/persiapan/pkka/">
                              <Button
                                htmlType="button"
                                type="primary"
                                danger
                                title="batalkan"
                              >
                                <CloseSquareOutlined /> Batal
                              </Button>
                            </Link>
                          </Space>
                        </Col>
                      </>
                    }
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>

      </>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  setConfig,
  ajaxHandler,
  ajaxViewHandler,
})(withRouter(FormProgramKerjaAudit));
