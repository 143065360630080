export const proses_bisnis = {
  router: [
    {
      name: "PROSES BISNIS",
      key: "proses_bisnis",
      path: "/admin/ppbr/proses-bisnis",
      apiUrl: {
        data: "ppbr/api/proses/bisnis/?tipe=1",
        preview: "ppbr/api/proses/bisnis/",
      },
      breadCrumb: [
        {
          label: "PPBR",
          link: "#",
          active: false,
        },
        {
          label: "PENILAIAN KEMATANGAN MR",
          link: "#",
          active: false,
        },
        {
          label: "PROSES BISNIS",
          link: "#",
          active: true,
        },
      ],
      component: "datatable2",
    },
  ],
  model: [
    {
      name: "id",
      isPk: true,
      isForm: false,
      isTable: false,
    },
    {
      name: "nama",
      label: "Nama",
      type: "text",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "dokumen",
      label: "Dokumen",
      type: "file",
      tableTipe: "download_link",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi",
          },
        ],
        update: [
          {
            required: false,
            message: null,
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "tipe",
      label: "Tipe",
      type: "text",
      defaultValue: 1,
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi",
          },
        ],
      },
      isForm: true,
      isTable: false,
    },
  ],
};
