export const laporan_prioritas = {
  router: [
    {
      name: "LAPORAN PRIORITAS",
      key: "laporan_prioritas",
      path: "/admin/ppbr/laporan-prioritas",
      apiUrl: {
        data: "ppbr/api/audit/universe/",
        preview: "ppbr/api/audit/universe/",
      },
      breadCrumb: [
        {
          label: "PPBR",
          link: "#",
          active: false,
        },
        {
          label: "PERINGKAT PRIORITAS",
          link: "#",
          active: false,
        },
        {
          label: "LAPORAN PRIORITAS",
          link: "#",
          active: true,
        },
      ],
      component: "datatable2",
      disabledAksi: true,
      btnExport: true
    },
  ],
  model: [
    {
      name: "id",
      isPk: true,
      isForm: false,
      isTable: false,
    },
    {
      name: "tahun",
      label: "Tahun",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "auditee",
      label: "Auditee",
      type: "select",
      tableValue: "auditee_nama",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi",
          },
        ],
      },
      isForm: true,
      isTable: true,
      references: {
        apiUrl: "master/api/auditee/",
        value: "id",
        label: "nama"
      }
    },
    {
      name: "level_inherin_risk",
      label: "Level Inherin Risk",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },

    {
      name: "bobot_inherin_risk",
      label: "Bobot Inherin Risk",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },

    {
      name: "nilai_inherin_risk",
      label: "Nilai Inherin Risk",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "skala_inherin_risk",
      label: "Skala Inherin Risk",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "asn",
      label: "Asn",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "non_asn",
      label: "Non Asn",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "jumlah_program",
      label: "Jumlah Program",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "program_skala",
      label: "Skala Program",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "jumlah_kegiatan",
      label: "Jumlah Kegiatan",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "kegiatan_skala",
      label: "Skala Kegiatan",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: "harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    

    {
      name: "jumlah_sub_kegiatan",
      label: "Jumlah Sub Kegiatan",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "sub_kegiatan_skala",
      label: "Skala Sub Kegiatan",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "anggaran_total",
      label: "Anggaran Total",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "persentase_perapbd_skala",
      label: "Skala Persentase Anggaran",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    
    {
      name: "realisasi_anggaran_total",
      label: "Realisasi",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "realisasi_skala",
      label: "Skala Realisasi",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "belanja_modal_jumlah",
      label: "Jumlah Belanja Modal",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "persentase_modal_skala",
      label: "Skala Persentase Modal",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },

    {
      name: "belanja_modal_jumlah_paket",
      label: "Jumlah Paket Belanja Modal",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "realisasi_belanja_modal",
      label: "Realisasi Anggaran Belanja Modal",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "nilai_akip",
      label: "Nilai Akip",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "nilai_akip_skala",
      label: "Skala Akip",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "nilai_ikm",
      label: "Nilai Ikm",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "nilai_ikm_skala",
      label: "Jumlah Program",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "diperiksa_apip",
      label: "Apip (Tahun Terakhir Diperiksa)",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "diperiksa_apip_skala",
      label: "Diperiksa Apip Skala",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "diperiksa_apep",
      label: "Apep (Tahun Terakhir Diperiksa)",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "diperiksa_apep_skala",
      label: "Diperiksa Apep Skala",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "tindak_lanjut_apip",
      label: "Tindak Lanjut Apip",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "tindak_lanjut_apip_skala",
      label: "Skala Tindak Lanjut Apip",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "tindak_lanjut_apep",
      label: "Tindak Lanjut Apep",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "tindak_lanjut_apep_skala",
      label: "Skala Tindak Lanjut Apep",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "nilai_faktor_resiko",
      label: "Nilai Faktor Resiko",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: false,
      isTable: true,
    }




  ],
};
