export const pertimbangan7 = {
  router: [
    {
      name: "NILAI AKIP DAN IKM",
      key: "strategi_pengawasan_pb",
      path: "/admin/ppbr/strategi-pengawasan",
      apiUrl: {
        data: "ppbr/api/resiko/area/tujuh/",
        preview: "ppbr/api/resiko/area/tujuh/",
      },
      breadCrumb: [
        {
          label: "PPBR",
          link: "#",
          active: false,
        },
        {
          label: "PENILAIAN KEMATANGAN MR",
          link: "#",
          active: false,
        },
        {
          label: "NILAI AKIP DAN IKM",
          link: "#",
          active: true,
        },
      ],
    },
  ],
  model: [
    {
      name: "id",
      isPk: true,
      isForm: false,
      isTable: false,
    },
    {
      name: "auditee_nama",
      label: "Area Pengawasan (Auditable Unit)",
      isForm: false,
      isTable: true,
      type: "label",
    },
    {
      name: "tahun",
      label: "Tahun",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: false,
    },
    {
      name: "akip",
      label: "AKIP",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "skala_akip",
      label: "Skala",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "ikm",
      label: "IKM",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "skala_ikm",
      label: "Skala",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
  ],
};
