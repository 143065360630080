import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers";
import axios from 'axios';
import axiosMiddleware from 'redux-axios-middleware';
import Config from '../Config';

const config = new Config();

const client = axios.create({ //all axios can be used, shown in axios documentation
  baseURL: config.get_server_url(),
  responseType: 'json'
});

export default createStore(
	rootReducer,
	applyMiddleware(
		axiosMiddleware(client)
	)
);
