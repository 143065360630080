import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import store from './store/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter, Route, Switch, Redirect, Link } from "react-router-dom";
import AuthLayout from "./layouts/AuthLayout.jsx";
import AdminLayout from "./layouts/AdminLayout.jsx";
import {PrivateRoute} from './PrivateRoute';


import { 
  Result,
  Button,
  ConfigProvider,
  Header
} from 'antd';

// import { initializeFirebase } from './push-notification';

ReactDOM.render(
  <ConfigProvider 
    theme={
      { 
        token: { 
          colorPrimary: '#5BA920',
          colorPrimaryBg: "#CDF9B2",
          colorBgBase: '#fff',
          colorLink: '#1677FF',
          borderRadius: 5,
          colorFillSecondary: '#CDF9B2'
          // colorPrimaryActive: 'blue',
          // colorBgTextActive: 'red',
          // controlItemBgActiveHover: 'black',
          // controlItemBgActive: 'green'
        },
        components: {
          // Header: {
          //     colorPrimary: '#5BA920'
          // }
          SubMenu: {
            colorPrimary: '#5BA920'
          },
        }
      }
    }
  >
    <Provider store={store}>
    	<BrowserRouter>
      	<Switch>
      		<Route path="/auth" component={AuthLayout} />          
      		<PrivateRoute path="/admin" component={AdminLayout} />
      		<Route from="/" render={() => <Redirect to="/admin/index"/>}/>{/*live server must off*/}

          
          <Route path="" render={
              () => 
                  <Result
                      status="404"
                      title={
                        <div>
                        404
                        </div>
                      }
                      subTitle={
                        <div>
                          <h1>Maaf, halaman tidak ditemukan</h1><br />
                          <img src={process.env.PUBLIC_URL + "/icon.svg"} height="50" /><br /><br />
                          <Link to="/">
                            <Button type="primary">
                              kembali ke halaman utama
                            </Button>
                          </Link>
                        </div>
                      }
                  />
          } />
      	</Switch>
      </BrowserRouter>
    </Provider>
  </ConfigProvider>,
  document.getElementById('root')
);

// initializeFirebase();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
