export const pertimbangan3 = {
  router: [
    {
      name: "ANGGARAN TOTAL",
      key: "strategi_pengawasan_pb",
      path: "/admin/ppbr/strategi-pengawasan",
      apiUrl: {
        data: "ppbr/api/resiko/area/tiga/",
        preview: "ppbr/api/resiko/area/tiga/",
      },
      breadCrumb: [
        {
          label: "PPBR",
          link: "#",
          active: false,
        },
        {
          label: "PENILAIAN KEMATANGAN MR",
          link: "#",
          active: false,
        },
        {
          label: "ANGGARAN TOTAL",
          link: "#",
          active: true,
        },
      ],
    },
  ],
  model: [
    {
      name: "id",
      isPk: true,
      isForm: false,
      isTable: false,
    },
    {
      name: "auditee_nama",
      label: "Area Pengawasan (Auditable Unit)",
      isForm: false,
      isTable: true,
      type: "label",
    },
    {
      name: "tahun",
      label: "Tahun",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: false,
    },
    {
      name: "anggaran_total",
      label: "Anggaran Total (n-1)",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "persentase",
      label: "Persentase terhadap APBD Kabupaten Bantul (%)",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "skala",
      label: "Skala",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
  ],
};
