import React, { PureComponent } from "react";
import { Redirect, Link } from "react-router-dom";
import { withRouter } from "react-router";
import {
  Button,
  Row,
  Col,
  Input,
  Layout,
  Form,
  Alert,
  Modal,
  message,
  Switch,
  Tabs,
  notification,
  Spin,
  Pagination,
  Tag,
  Breadcrumb,
  Card,
  Space,
  Table,
  Popconfirm,
  Skeleton,
  Select,
  InputNumber,
  Radio,
  Checkbox,
  DatePicker,
  Divider,
} from "antd";
import {
  TeamOutlined,
  SearchOutlined,
  PlusOutlined,
  FormOutlined,
  CloseSquareOutlined,
  ExclamationCircleOutlined,
  DiffOutlined,
  RedoOutlined,
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
  SaveOutlined,
  BookOutlined
} from "@ant-design/icons";
// import "@ant-design/compatible/assets/index.css";
import Config from "../Config";
import { Helmet } from "react-helmet";

import { Editor } from "@tinymce/tinymce-react";

import { connect } from "react-redux";
import { setConfig, ajaxHandler, ajaxViewHandler } from "../store/actions";

import moment from "moment";

import axios from "axios";
import Cookies from "js-cookie";
import { temuan } from "../models/master/temuan";

class NotisiSementaraHasilAudit extends PureComponent {
  config;
  timeoutGetQr;
  timeOutPushQr;
  formRef = React.createRef();
  formRefModal = React.createRef();
  focusComponent = "";
  pk = "";

  constructor(props) {
    super(props);
    this.config = new Config();
    this.state = {
      nama: "",
      openDetailReview: false,
      jenisReview: "approval_satu",
      openReview: false,
      catatan: "",
      loadingEditor: true,
      suratTugas: null,
      errTitle: "",
      errMsg: "",
      loadingData: false,
      pagination: { pageSize: 10, current: 1, total: 0 },
      searchText: "",
      dataSource: [],
      prevSource: [],
      columns: [
        {
          title: "Nomor Surat",
          width: 150,
          dataIndex: "surat_tugas_nomor",
          key: "surat_tugas_nomor",
        },
        {
          title: "Notisi Hasil Audit",
          width: 150,
          dataIndex: "id",
          key: "notisi_hasil_audit",
          align: "center",
          render: (value, row, index) => {
            return (
              <Button
                type="text"
                style={{ color: "#1677FF" }}
              >
                Lihat
              </Button>
            )
          },
        },
        {
          title: "Tanggal Pembuatan",
          width: 150,
          dataIndex: "created_at",
          key: "created_at",
          align: "center",
          render: (value, row, index) => {
            return value != null ? moment(value).format("DD-MM-YYYY") : "-";
          },
        },
        {
          title: "File Cetak TTD",
          width: 150,
          dataIndex: "lampiran",
          key: "ttd",
          align: "center",
          render: (value, row, index) => {
            return (
              <>
                {value == null
                  ?
                  <>
                    <Button
                      size="small"
                      type="primary"
                      success
                      ghost
                      onClick={() => {
                        document.getElementById("lamp" + row.id).click();
                      }}
                    >
                      Upload
                    </Button>
                    <Input
                      id={"lamp" + row.id}
                      style={{ display: "none" }}
                      type="file"
                      onChange={(e) => this.setFile(e, "lampiran", row.id)}
                      onClick={(event) => {
                        const { target = {} } = event || {};
                        target.value = "";
                      }}
                      accept="application/pdf, image/jpeg, image/png, image/jpg"
                    />
                  </>
                  :
                  <a href={value} target="_blank">
                    Lihat
                  </a>
                }
              </>
            )
          }
        },
        {
          title: "Status Cek",
          children: [
            {
              title: 'Danis',
              dataIndex: 'aprroval_satu',
              key: 'aprroval_satu',
              width: 100,
              align: 'center',
              render: (value, row, index) => {
                // (1,"Belum"), (2, "Disetujui"), (3, "Catatan"),(4, "Ditolak")))
                let color1 = "default";
                if (value == "2") {
                  color1 = "success"
                } else if (value == "3") {
                  color1 = "warning"
                } else if (value == "4") {
                  color1 = "error"
                }
                return (
                  <Button
                    size="small"
                    type="text"
                    onClick={() => {
                      this.setState({
                        jenisReview: "aprroval_satu",
                        suratTugas: row,
                        openDetailReview: true,
                      });
                    }}
                  >
                    <Tag
                      color={color1}
                    >
                      {row.aprroval_satu_nama}
                    </Tag>
                  </Button>
                )
              },
            },
            {
              title: 'Irban',
              dataIndex: 'aprroval_dua',
              key: 'aprroval_dua',
              width: 100,
              align: 'center',
              render: (value, row, index) => {
                // (1,"Belum"), (2, "Disetujui"), (3, "Catatan"),(4, "Ditolak")))
                let color2 = "default";
                if (value == "2") {
                  color2 = "success"
                } else if (value == "3") {
                  color2 = "warning"
                } else if (value == "4") {
                  color2 = "error"
                }
                return (
                  <Button
                    type="text"
                    onClick={() => {
                      this.setState({
                        jenisReview: "aprroval_dua",
                        suratTugas: row,
                        openDetailReview: true,
                      });
                    }}
                  >
                    <Tag color={color2}>{row.aprroval_dua_nama}</Tag>
                  </Button>
                );
              },
            },
          ]
        },
        {
          title: "Aksi",
          width: 150,
          fixed: "right",
          dataIndex: "id",
          key: "aksi",
          align: "center",
          render: (value, row, index) => {
            return (
              <Space>
                <Button
                  title="buat kartu tugas"
                  type="primary"
                  size="small"
                  ghost
                  success
                  htmlType="button"
                  onClick={() => {
                    this.showReview(row)
                  }}
                >
                  review
                </Button>
                <Button
                  size="small"
                  title="edit notisi, temuan, hal"
                  type="primary"
                  ghost
                  default
                  htmlType="button"
                  onClick={() => {
                    this.showInsert(row)
                  }}
                >
                  <BookOutlined />
                </Button>
                <Button
                  size="small"
                  type="primary"
                  ghost
                  danger
                  htmlType="button"
                  onClick={() => {
                    this.showDeleteConfirm(value)
                  }}
                >
                  <DeleteOutlined />
                </Button>
              </Space>
            )
          },
        },
      ],
      tahunDipilih: new Date().getFullYear(),
      tambah: false,
      aksi: "edit",
      method: "post",
      idLama: "",
      editingKey: "",
      open: false,
      filterJenis: 1,

      anggota: [],
      hariAnggota: [],

      loadingSubmitTemuan: [false],

      temuan: [
        {
          aksi_temuan: "post",
          judul: null,
          kondisi: null,
          kriteria: null,
          sebab: [
            {
              kode: null,
              akibat: null,
            }
          ],
          rekomendasi: [
            {
              kode: null
            }
          ],
          nominal: null,
          status: null,
          uraian: null,
        },
      ],

      hal: [
        {
          aksi_hal: "post",
          judul: null,
          kondisi: null,
          saran: null,
          uraian: null,
        },
      ],
    };
  }

  async getMasterList(stateName, url, isNext = false) {
    this.setState({
      ["loading" + stateName]: !isNext,
      ["loadingNext" + stateName]: isNext,
      ["loadingSearch" + stateName]: isNext,
    });
    this.props
      .ajaxViewHandler("get", url)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (data.count != undefined) {
            let newState = null;
            if (
              this.state["data" + stateName] == undefined ||
              this.state["data" + stateName] == null
            ) {
              newState = data;
            } else {
              if (data.previous == null) {
                //first data
                newState = data;
              } else {
                const oldState = this.state["data" + stateName];
                newState = {
                  count: data.count,
                  next: data.next,
                  previous: data.previous,
                  results: [...oldState.results, ...data.results],
                };
              }
            }
            // console.log('state_name', destination.stateDestination);
            // console.log('state_value', newState);
            this.setState(
              {
                ["data" + stateName]: newState,
              },
              () => {
                if (stateName == "temuan" || stateName == "sebab" || stateName == "rekomendasi" || stateName == "kategori_rekomendasi") {
                  let temp = [];
                  data.results.map((item, idx) => {
                    temp = [
                      ...temp,
                      {
                        value: item.id,
                        label: item.nama,
                      },
                    ];
                  });
                  this.setState({
                    ["data" + stateName]: temp,
                  });
                }
              }
            );
          } else if (data.results != undefined) {
            this.setState({
              ["data" + stateName]: data.results,
            });
          } else {
            if (Array.isArray(data)) {
              this.setState({
                ["data" + stateName]: data,
              });
            } else {
              this.setState({
                ["data" + stateName]: [data],
              });
            }
          }
        } else {
          console.log(data);
        }
        this.setState({
          ["loading" + stateName]: false,
          ["loadingNext" + stateName]: false,
          ["loadingSearch" + stateName]: false,
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          ["loading" + stateName]: false,
          ["loadingNext" + stateName]: false,
          ["loadingSearch" + stateName]: false,
        });
      });
  }

  generateYears = () => {
    var start = 2020;
    var end = new Date().getFullYear() + 3;
    var items = [];
    for (var i = start; i <= end; i++) {
      items = [
        ...items,
        {
          value: i,
          label: i,
        },
      ];
    }
    this.setState(
      {
        yearItems: items,
      },
      () => {
        // console.log('year', this.state.yearItems)
        this.formRef.current.setFieldsValue({
          tahun: new Date().getFullYear(),
        });
      }
    );
  };

  generateSelectAsync = (stateName, url, label, value, idxAnggota = -1) => {
    return this.state["loading" + stateName] ? (
      <Skeleton active paragraph={false} />
    ) : (
      <Select
        style={{ minWidth: 200 }}
        dropdownMatchSelectWidth={true}
        id={stateName}
        showSearch
        placeholder="pilih"
        optionFilterProp="children"
        allowClear
        onSearch={(val) => {
          let tempUrl = "";
          if (url.includes("?")) {
            tempUrl = url + "&search=" + val;
          } else {
            tempUrl = url + "?search=" + val;
          }
          this.getMasterList(stateName, tempUrl, false);
        }}
        onPopupScroll={(e) => {
          e.persist();
          let target = e.target;
          if (target.scrollTop + target.offsetHeight >= target.scrollHeight) {
            if (
              this.state["data" + stateName].next != undefined &&
              this.state["data" + stateName].next != null
            ) {
              this.getMasterList(
                stateName,
                this.state["data" + stateName].next,
                true
              );
            }
          }
        }}
        onChange={(val) => {
          if (stateName == "pegawai" || stateName == "jabatan_tugas") {
            let temp = [...this.state.anggota];
            temp[idxAnggota][stateName] = val;
            this.setState({
              anggota: temp,
            });
          }
        }}
      >
        {this.state["loadingSearch" + stateName] && (
          <Skeleton active paragraph={false} />
        )}
        {this.state["data" + stateName] != undefined &&
          this.state["data" + stateName] != null && (
            <>
              {this.state["data" + stateName].results != undefined
                ? this.state["data" + stateName].results.map((item) => (
                  <Select.Option value={item[value]}>
                    {item[label]}
                  </Select.Option>
                ))
                : this.state["data" + stateName].map((item) => (
                  <Select.Option value={item[value]}>
                    {item[label]}
                  </Select.Option>
                ))}
            </>
          )}
        {this.state["loadingNext" + stateName] && (
          <Skeleton active paragraph={false} />
        )}
      </Select>
    );
  };

  setFile(e, name, id) {
    this.setState(
      {
        [name + "_file"]: e.target.files[0],
      },
      () => {
        // console.log(this.state[name+"_file"])
        let params = new FormData();
        params.append("lampiran", this.state[name + "_file"], this.state[name + "_file"].name)
        this.doUploadLampiran(params, id);
      }
    );
  }

  showReview(row) {
    this.setState(
      {
        suratTugas: row,
        openReview: true,
      },
      () => {
      }
    );
  }

  showInsert(row) {
    let tempTemuan = [
      {
        aksi_temuan: "post",
        judul: null,
        kondisi: null,
        kriteria: null,
        sebab: [
          {
            kode: null,
            akibat: null,
          }
        ],
        rekomendasi: [
          {
            kode: null
          }
        ],
        nominal: null,
        status: null,
        uraian: null,
      },
    ];

    let tempHal = [
      {
        aksi_hal: "post",
        judul: null,
        kondisi: null,
        saran: null,
        uraian: null,
      },
    ];

    let temuanExist = row.temuan;
    temuanExist.map((item, idx) => {
      if (item.sebab.length == 0) {
        temuanExist[idx]["sebab"] = [
          {
            kode: null,
            akibat: null,
          }
        ];
      }
      if (item.rekomendasi.length == 0) {
        temuanExist[idx]["rekomendasi"] = [
          {
            kode: null
          }
        ];
      }
    });

    let halExist = row.hal;
    this.setState(
      {
        previewData: row,
        open: true,
        aksi: "edit",
        idLama: "",
        method: "put",
        editingKey: "",
        // anggota: tempAnggota,
        // hariAnggota: row.anggota,
        temuan: row.temuan.length > 0 ? temuanExist : tempTemuan,
        hal: row.hal.length > 0 ? halExist : tempHal
      },
      () => {
        this.formRefModal.current.resetFields();
        this.formRefModal.current.setFieldsValue({
          nomor_surat: row.surat_tugas_nomor,
          nama: row.nama
        });
      }
    );
  }

  showEdit = (record) => {
    this.formRef.current.resetFields();

    let tempData = this.state.dataSource;

    this.setState(
      {
        prevSource: tempData,
        editingKey: record.id,
      },
      () => {
        this.preview(record.id);
      }
    );
    // this.formRef.current.setFieldsValue({
    //   nama: '',
    //   ...record,
    // });
    // this.setState({
    //   editingKey: record.id
    // }, console.log('editingkey', record.id));
  };

  async preview(id) {
    this.setState({
      loadingData: true,
      errTitle: "",
      errMsg: "",
    });

    this.props
      .ajaxViewHandler("get", this.props.configClass.apiUrl.preview + id)
      .then(() => {
        // setTimeout(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          // this.formRef.current.setFieldsValue(data);
          for (var key in data) {
            const dateRegex = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/;
            const dateTimeRegex =
              /^[0-9]{4}\-[0-9]{2}\-[0-9]{2} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;
            if (dateRegex.test(data[key])) {
              //jika format tanggal
              this.formRef.current.setFieldsValue({
                [key + "_picker"]: moment(new Date(data[key]), "DD-MM-YYYY"),
                [key]: moment(new Date(data[key]), "DD-MM-YYYY"),
              });
            } else if (dateTimeRegex.test(data[key])) {
              //jika format tanggal dan jam
              this.formRef.current.setFieldsValue({
                [key + "_picker"]: moment(
                  new Date(data[key]),
                  "DD-MM-YYYY HH:mm:ss"
                ),
                [key]: moment(new Date(data[key]), "DD-MM-YYYY HH:mm:ss"),
              });
            } else {
              if (key == "body") {
                this.setState({
                  artikelString: data[key],
                });
                this.formRef.current.setFieldsValue({
                  content_editor: data[key],
                  body_editor: data[key],
                });
              } else if (key == "tag") {
                let temp = [];
                data[key].map((item) => {
                  temp = [
                    ...temp,
                    {
                      value: item.id,
                      label: item.nama,
                      key: item.id,
                    },
                  ];
                });
                this.formRef.current.setFieldsValue({
                  tags: temp,
                });
              } else if (key == "image" || key == "dokumen") {
                // let temp = [];
                // let tempGb = [];
                // data[key].map((item) => {
                //   temp = [
                //     ...temp,
                //     {
                //       id: item.id,
                //       name: item.name,
                //     },
                //   ];
                //   tempGb = [
                //     ...tempGb,
                //     {
                //       id: item.id,
                //       name: item.name,
                //       path: item.path,
                //     },
                //   ];
                // });
                // this.setState({
                //   gbr_post: temp,
                //   gambar_edit: tempGb,
                // });
              } else {
                this.formRef.current.setFieldsValue({
                  [key]: data[key],
                });
              }
            }

            // if (key == "icon") {
            //   this.setState({
            //     preview_gambar: data[key],
            //   });
            // }
          }
          message.destroy();
          this.setState(
            {
              aksi: "edit",
              method: "put",
              idLama: data.id + "/",
              loadingData: false,
            },
            () => {
              if (this.focusComponent != null && this.focusComponent != "") {
                document.getElementById(this.focusComponent).focus();
              }
            }
          );
        } else {
          const errTitle = "error preview data lama";
          this.setState({
            errTitle: errTitle,
            errMsg: JSON.stringify(data),
            loadingData: false,
          }, () => {
            document.getElementById('error-alert').scrollIntoView();
          });
        }
        // }, 500);
      })
      .catch((response) => {
        this.setState({
          errTitle: "gagal get data",
          errMsg: JSON.stringify(response),
          loadingData: false,
        }, () => {
          document.getElementById('error-alert').scrollIntoView();
        });
      });
  }

  // editableCell = (
  //   editing,
  //   dataIndex,
  //   title,
  //   inputType,
  //   record,
  //   index,
  //   children,
  //   ...restProps
  // ) => {
  //   const inputNode = <Input />;
  //   return (
  //     <td>
  //       {editing != undefined && editing ? (
  //         <Form.Item
  //           name={dataIndex}
  //           style={{
  //             margin: 0,
  //           }}
  //           rules={[
  //             {
  //               required: true,
  //               message: `Please Input ${title}!`,
  //             },
  //           ]}
  //         >
  //           {inputNode}
  //         </Form.Item>
  //       ) : (
  //         children
  //       )}
  //     </td>
  //   );
  // };

  componentDidMount() {
    // console.log('didmount', this.props)
    this.generateYears();
    this.getData();
    const models = [
      {
        name: "temuan",
        apiUrl: "master/api/temuan/",
        value: "id",
        label: "nama",
      },
      {
        name: "sebab",
        apiUrl: "master/api/sebab/",
        value: "id",
        label: "nama",
      },
      {
        name: "rekomendasi",
        apiUrl: "master/api/kode/rekomendasi/",
        value: "id",
        label: "nama",
      },
      {
        name: "kategori_rekomendasi",
        apiUrl: "master/api/kategori/rekomendasi/",
        value: "id",
        label: "nama",
      },
    ];
    models.map((item, idx) => {
      this.getMasterList(item.name, item.apiUrl);
    });
  }

  addTemuan() {
    let temp = this.state.temuan;
    temp = [
      ...temp,
      {
        aksi_temuan: "post",
        judul: null,
        kondisi: null,
        kriteria: null,
        sebab: [
          {
            kode: null,
            akibat: null,
          }
        ],
        rekomendasi: [
          {
            kode: null,
            kategori: null,
          }
        ],
        nominal: null,
        status: null,
        uraian: null,
      },
    ];
    let tempLoadingTemuan = this.state.loadingSubmitTemuan;
    tempLoadingTemuan = [...tempLoadingTemuan, false];
    this.setState({
      temuan: temp,
      loadingSubmitTemuan: tempLoadingTemuan
    }, () => {

    });
  }

  addSebabAkibat(idxTemuan) {
    let temp = this.state.temuan;
    console.log('temp', temp);
    let tempSebabAkibat = temp[idxTemuan]["sebab"];
    tempSebabAkibat = [
      ...tempSebabAkibat,
      {
        kode: null,
        akibat: null,
      },
    ];
    temp[idxTemuan]["sebab"] = tempSebabAkibat;
    this.setState({
      temuan: [...temp],
    }, () => {
      console.log('temuan', this.state.temuan);
    });
  }

  addRekomendasi(idxTemuan) {
    let temp = this.state.temuan;
    console.log('temp', temp);
    let tempRekomendasi = temp[idxTemuan]["rekomendasi"];
    tempRekomendasi = [
      ...tempRekomendasi,
      {
        kode: null,
        kategori: null,
      },
    ];
    temp[idxTemuan]["rekomendasi"] = tempRekomendasi;
    this.setState({
      temuan: [...temp],
    }, () => {
      console.log('temuan', this.state.temuan);
    });
  }

  addHal() {
    let temp = this.state.hal;
    temp = [
      ...temp,
      {
        aksi_hal: "post",
        judul: null,
        kondisi: null,
        saran: null,
        uraian: false,
      },
    ];
    this.setState({
      hal: temp,
    }, () => {

    });
  }

  hapusAnggota(idx) {
    const tempAgt = [...this.state.anggota];
    tempAgt.splice(idx, 1);
    this.setState({
      anggota: tempAgt,
    });
  }

  async getData(
    limit = this.state.pagination.pageSize,
    offset = 0,
    search = this.state.searchText
  ) {
    this.setState({ loadingData: true, errTitle: "", errMsg: "" });

    let baseUrl = this.props.configClass.apiUrl.data;
    let url = "";
    if (baseUrl.includes("?")) {
      url =
        baseUrl +
        "&tahun=" +
        this.state.tahunDipilih +
        "&limit=" +
        limit +
        "&offset=" +
        offset +
        "&search=" +
        search;
    } else {
      url =
        baseUrl +
        "?limit=" +
        limit +
        "&tahun=" +
        this.state.tahunDipilih +
        "&offset=" +
        offset +
        "&search=" +
        search;
    }

    if (this.state.filterJenis != "") {
      url += "&jenis=" + this.state.filterJenis;
    }

    this.props
      .ajaxViewHandler("get", url)
      .then((results) => {
        // setTimeout(() => {
        const res = this.props.responseMsg;
        if (this.props.isSuccess) {
          let pagination = { ...this.state.pagination };
          pagination.total = res.count;
          pagination.current = offset + 1;
          this.setState({
            loadingData: false,
            dataSource: res.results,
            pagination,
          });
        } else {
          const errTitle = "error get data code 1";
          this.setState({
            loadingData: false,
            errTitle: errTitle,
            errMsg: JSON.stringify(res),
          }, () => {
            document.getElementById('error-alert').scrollIntoView();
          });
        }
        // }, 500);
      })
      .catch((response) => {
        const errTitle = "error get data code 2";
        this.setState({
          loadingData: false,
          errTitle: errTitle,
          errMsg: JSON.stringify(response),
        }, () => {
          document.getElementById('error-alert').scrollIntoView();
        });
      });
  }

  handleTableChange = async (pagination, filters, sorter) => {
    const newPagination = { ...this.state.pagination };
    newPagination.pageSize = pagination.pageSize;
    this.setState({
      loadingData: true,
      pagination,
    });
    if (pagination.current == 1) {
      this.getData(pagination.pageSize);
    } else {
      this.getData(
        pagination.pageSize,
        (pagination.current - 1) * pagination.pageSize
      );
    }
  };

  showDeleteConfirm(item) {
    Modal.confirm({
      title: "Konfirmasi Hapus?",
      icon: <ExclamationCircleOutlined />,
      content: "yakin menghapus data?",
      okText: "Ya",
      okType: "danger",
      cancelText: "Tidak",
      onOk: () => this.prosesHapus(item),
      onCancel() {
        //console.log('Cancel');
      },
    });
  }

  resetStatePost() {
    this.setState({
      aksi: "edit",
      method: "put",
      idLama: "",
      tambah: false,
      editingKey: "",
      open: false,
      anggota: [],
      hariAnggota: [],
      nama: "",
      temuan: [
        {
          aksi_temuan: "post",
          judul: null,
          kondisi: null,
          kriteria: null,
          sebab: [
            {
              kode: null,
              akibat: null,
            }
          ],
          rekomendasi: [
            {
              kode: null
            }
          ],
          nominal: null,
          status: null,
          uraian: null,
        },
      ],

      hal: [
        {
          aksi_hal: "post",
          judul: null,
          kondisi: null,
          saran: null,
          uraian: false,
        },
      ],

      rencana_kunjungan: [],
      catatan: "",
    });
  }

  async doUploadLampiran(datas, id) {
    this.setState({
      loadingData: true,
      errTitle: "",
      errMsg: "",
    });
    // message.loading({ content: 'Memproses...', key: 'toast' });

    this.props
      .ajaxHandler(
        "put",
        this.props.configClass.apiUrl.lampiran + id + "/",
        datas,
        true,
        true
      )
      .then((res) => {
        // setTimeout(() => {
        this.setState({
          loadingData: false,
        });

        if (res.type == "SUCCESS_HANDLER") {
          notification.success({
            message: "sukses",
            description: "berhasil mengupload berkas.",
            placement: "bottomRight",
          });
          this.getData();
        } else {
          notification.error({
            message: "gagal",
            description: "gagal mengupload berkas.",
            placement: "bottomRight",
          });
          const errTitle = "gagal menyimpan data";
          // console.log("tes", res);
          this.setState({
            errTitle: errTitle,
            errMsg:
              res.error.response.data.message != undefined
                ? JSON.stringify(res.error.response.data.message)
                : errTitle,
          }, () => {
            document.getElementById('error-alert').scrollIntoView();
          });
        }
        // }, 0);
      })
      .catch((response) => {
        notification.error({
          message: "gagal",
          description: "gagal mengupload berkas.",
          placement: "bottomRight",
        });
        this.setState({
          loadingData: false,
          errTitle: "gagal menyimpan",
          errMsg: JSON.stringify(response),
        }, () => {
          document.getElementById('error-alert').scrollIntoView();
        });
      });
  }

  async prosesReview(status) {
    var params = new FormData();
    params.append("reference", this.state.suratTugas.id);
    params.append("status", status);
    params.append("catatan", this.state.catatan);
    params.append("tipe", 5);

    this.setState({
      loadingData: true,
      errTitle: "",
      errMsg: "",
    });
    // message.loading({ content: 'Memproses...', key: 'toast' });

    this.props
      .ajaxHandler(
        "post",
        this.props.configClass.apiUrl.review,
        params,
        true,
        true
      )
      .then((res) => {
        // setTimeout(() => {
        this.setState({
          loadingData: false,
        });

        if (res.type == "SUCCESS_HANDLER") {
          notification.success({
            message: "sukses",
            description: "berhasil menyimpan review.",
            placement: "bottomRight",
          });
          this.resetStatePost();
          this.getData();
        } else {
          notification.error({
            message: "gagal",
            description: "gagal menyimpan review.",
            placement: "bottomRight",
          });
          const errTitle = "gagal menyimpan data";
          // console.log("tes", res);
          this.setState({
            errTitle: errTitle,
            errMsg:
              res.error.response.data.message != undefined
                ? JSON.stringify(res.error.response.data.message)
                : errTitle,
          }, () => {
            document.getElementById('error-alert').scrollIntoView();
          });
        }
        // }, 0);
      })
      .catch((response) => {
        notification.error({
          message: "gagal",
          description: "gagal menyimpan review.",
          placement: "bottomRight",
        });
        this.setState({
          loadingData: false,
          errTitle: "gagal menyimpan",
          errMsg: JSON.stringify(response),
        }, () => {
          document.getElementById('error-alert').scrollIntoView();
        });
      });
  }

  async postData(datas) {
    this.setState({
      loadingData: true,
      errTitle: "",
      errMsg: "",
    });
    // message.loading({ content: 'Memproses...', key: 'toast' });

    this.props
      .ajaxHandler(
        "put",
        this.props.configClass.apiUrl.preview + this.state.previewData.id + "/",
        datas,
        false,
        true
      )
      .then((res) => {
        // setTimeout(() => {
        this.setState({
          loadingData: false,
        });

        if (res.type == "SUCCESS_HANDLER") {
          notification.success({
            message: "sukses",
            description: "berhasil menyimpan.",
            placement: "bottomRight",
          });
          this.resetStatePost();
          this.getData();
        } else {
          notification.error({
            message: "gagal",
            description: "gagal menyimpan.",
            placement: "bottomRight",
          });
          const errTitle = "gagal menyimpan data";
          // console.log("tes", res);
          this.setState({
            errTitle: errTitle,
            errMsg:
              res.error.response.data.message != undefined
                ? JSON.stringify(res.error.response.data.message)
                : errTitle,
          }, () => {
            document.getElementById('error-alert').scrollIntoView();
          });
        }
        // }, 0);
      })
      .catch((response) => {
        notification.error({
          message: "gagal",
          description: "gagal menyimpan.",
          placement: "bottomRight",
        });
        this.setState({
          loadingData: false,
          errTitle: "gagal menyimpan",
          errMsg: JSON.stringify(response),
        }, () => {
          document.getElementById('error-alert').scrollIntoView();
        });
      });
  }

  showActiveConfirm(item) {
    Modal.confirm({
      title: "Konfirmasi Activate?",
      icon: <ExclamationCircleOutlined />,
      content: "yakin mengaktivate data?",
      okText: "Ya",
      okType: "danger",
      cancelText: "Tidak",
      onOk: () => this.prosesActivate(item),
      onCancel() {
        //console.log('Cancel');
      },
    });
  }

  prosesHapus(id) {
    this.setState({
      loadingData: true,
      errMsg: "",
      errTitle: "",
    });

    this.props
      .ajaxHandler("delete", this.props.configClass.apiUrl.hapus + id + "/")
      .then((res) => {
        if (res.type == "SUCCESS_HANDLER") {
          notification.success({
            message: "sukses",
            description: "berhasil menghapus.",
            placement: "bottomRight",
          });
          this.getData();
        } else {
          this.setState({
            errTitle: "gagal menghapus",
            errMsg: JSON.stringify(res.payload.data.message),
            loadingData: false,
          }, () => {
            document.getElementById('error-alert').scrollIntoView();
          });
        }
      })
      .catch((response) => {
        this.setState({
          loadingData: false,
          errTitle: "gagal menghapus",
          errMsg: JSON.stringify(response),
        }, () => {
          document.getElementById('error-alert').scrollIntoView();
        });
      });
  }

  prosesActivate(id) {
    this.setState({
      loadingData: true,
      errMsg: "",
      errTitle: "",
    });

    this.props
      .ajaxHandler(
        "get",
        this.props.configClass.apiUrl.data + "active/" + id + "/"
      )
      .then((res) => {
        if (res.type == "SUCCESS_HANDLER") {
          notification.success({
            message: "sukses",
            description: "berhasil mengactivate data.",
            placement: "bottomRight",
          });
          this.getData();
        } else {
          this.setState({
            errTitle: "gagal mengactivate data",
            errMsg: JSON.stringify(res.payload.data.message),
            loadingData: false,
          }, () => {
            document.getElementById('error-alert').scrollIntoView();
          });
        }
      })
      .catch((response) => {
        this.setState({
          loadingData: false,
          errTitle: "gagal menghapus",
          errMsg: JSON.stringify(response),
        }, () => {
          document.getElementById('error-alert').scrollIntoView();
        });
      });
  }

  handleKeyUp = (event) => {
    // Enter
    if (event.keyCode === 13) {
      this.formRef.current.submit();
    }
  };

  handleSubmitHal = (values, idxHal) => {
    var params = {};
    for (var key in values) {
      if (values[key] == undefined) {
        params = { ...params, [key]: "" };
      } else {
        // datas.append(key, values[key].toString());
        params = { ...params, [key]: values[key].toString() };
      }
    }
    params = { ...params, uraian: this.state.hal[idxHal]["uraian"] };
    params = { ...params, notisi: this.state.previewData.id };

    let method = "post";
    let id = "";
    if (this.state.hal[idxHal]["id"] != undefined && this.state.hal[idxHal]["id"] != null) {
      method = "put";
      id = this.state.hal[idxHal]["id"] + "/";
    }
    this.postDataHal(params, method, id, idxHal)
    
    // this.postDataHal(params, idxHal)
  };

  async postDataHal(datas, method, id, idxHal) {
    message.loading({ content: 'Mohon menunggu, sedang memproses...', duration: 2, key: 'toast' });
    this.setState({
      loadingData: true,
      errTitle: "",
      errMsg: "",
    });
    // message.loading({ content: 'Memproses...', key: 'toast' });

    this.props
      .ajaxHandler(
        method,
        this.props.configClass.apiUrl.hal + id,
        datas,
        false,
        true
      )
      .then((res) => {
        // setTimeout(() => {
        this.setState({
          loadingData: false,
        });

        if (res.type == "SUCCESS_HANDLER") {
          notification.success({
            message: "sukses",
            description: "berhasil menyimpan hal.",
            placement: "bottomRight",
          });
          // this.resetStatePost();
          // this.getData();
          let tempHal = this.state.hal;
          tempHal[idxHal] = res.payload.data
          this.setState({
            hal: tempHal
          });
        } else {
          notification.error({
            message: "gagal",
            description: "gagal menyimpan hal.",
            placement: "bottomRight",
          });
          const errTitle = "gagal menyimpan hal";
          // console.log("tes", res);
          this.setState({
            errTitle: errTitle,
            errMsg:
              res.error.response.data.message != undefined
                ? JSON.stringify(res.error.response.data.message)
                : errTitle,
          }, () => {
            document.getElementById('error-alert').scrollIntoView();
          });
        }
        // }, 0);
      })
      .catch((response) => {
        notification.error({
          message: "gagal",
          description: "gagal menyimpan.",
          placement: "bottomRight",
        });
        this.setState({
          loadingData: false,
          errTitle: "gagal menyimpan",
          errMsg: JSON.stringify(response),
        }, () => {
          document.getElementById('error-alert').scrollIntoView();
        });
      });
  }

  handleSubmitTemuan = (values, idxTemuan) => {
    var params = {};
    for (var key in values) {
      if (values[key] == undefined) {
        params = { ...params, [key]: "" };
      } else {
        // datas.append(key, values[key].toString());
        params = { ...params, [key]: values[key].toString() };
      }
    }
    params = { ...params, sebab: this.state.temuan[idxTemuan]["sebab"] };
    params = { ...params, rekomendasi: this.state.temuan[idxTemuan]["rekomendasi"] };
    params = { ...params, uraian: this.state.temuan[idxTemuan]["uraian"] };
    params = { ...params, notisi: this.state.previewData.id };
    // console.log(params);
    let method = "post";
    let id = "";
    if (this.state.temuan[idxTemuan]["id"] != undefined && this.state.temuan[idxTemuan]["id"] != null) {
      method = "put";
      id = this.state.temuan[idxTemuan]["id"] + "/";
    }
    this.postDataTemuan(params, method, id, idxTemuan)
  };

  async postDataTemuan(datas, method, id, idxTemuan) {
    message.loading({ content: 'Mohon menunggu, sedang memproses...', duration: 2, key: 'toast' });
    this.setState({
      loadingData: true,
      errTitle: "",
      errMsg: "",
    });
    // message.loading({ content: 'Memproses...', key: 'toast' });

    this.props
      .ajaxHandler(
        method,
        this.props.configClass.apiUrl.temuan + id,
        datas,
        false,
        true
      )
      .then((res) => {
        // setTimeout(() => {
        this.setState({
          loadingData: false,
        });

        if (res.type == "SUCCESS_HANDLER") {
          notification.success({
            message: "sukses",
            description: "berhasil menyimpan temuan.",
            placement: "bottomRight",
          });
          // this.resetStatePost();
          // this.getData();
          // console.log(res.payload.data);
          let tempTemuan = this.state.temuan;
          tempTemuan[idxTemuan] = res.payload.data
          this.setState({
            temuan: tempTemuan
          });
        } else {
          notification.error({
            message: "gagal",
            description: "gagal menyimpan temuan.",
            placement: "bottomRight",
          });
          const errTitle = "gagal menyimpan temuan";
          // console.log("tes", res);
          this.setState({
            errTitle: errTitle,
            errMsg:
              res.error.response.data.message != undefined
                ? JSON.stringify(res.error.response.data.message)
                : errTitle,
          }, () => {
            document.getElementById('error-alert').scrollIntoView();
          });
        }
        // }, 0);
      })
      .catch((response) => {
        notification.error({
          message: "gagal",
          description: "gagal menyimpan.",
          placement: "bottomRight",
        });
        this.setState({
          loadingData: false,
          errTitle: "gagal menyimpan",
          errMsg: JSON.stringify(response),
        }, () => {
          document.getElementById('error-alert').scrollIntoView();
        });
      });
  }

  handleSubmitNotisi = (values) => {
    var params = {};
    params = { ...params, nama: this.state.nama };
    params = { ...params, surat_tugas: this.state.previewData.surat_tugas };
    // params = { ...params, surat_tugas: this.state.suratTugas.surat_tugas };

    // console.log(params)

    this.postData(params);
  };

  render() {
    return (
      <>
        {/*<!-- BEGIN: Content-->*/}
        <Breadcrumb>
          {this.props.breadCrumb.map((item, idx) => (
            <Breadcrumb.Item key={"bread" + idx}>{item.label}</Breadcrumb.Item>
          ))}
        </Breadcrumb>
        <div className="card">
          <Row className="isi">
            <Col span={24}>
              <Card
                className="konten"
                title={
                  <Row>
                    <Col lg={{ span: 20 }} xs={{ span: 24 }}>
                      <span className="konten-judul">
                        {this.props.configClass.name}
                      </span>
                    </Col>
                    <Col
                      lg={{ span: 4 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      style={{ textAlign: "right" }}
                    >
                      <Space>
                        <Button
                          loading={this.state.loadingData}
                          type="default"
                          size="small"
                          onClick={() => this.getData()}
                        >
                          <RedoOutlined />
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                }
              >
                <Alert
                  message="Tips"
                  description="klik pada baris kolom status cek untuk melihat hasil review."
                  type="info"
                  showIcon
                  closable
                />
                <Form
                  name="finput"
                  // onKeyUp={this.handleKeyUp}
                  onFinish={this.handleSubmit}
                  autoComplete="off"
                  ref={this.formRef}
                >
                  <Table
                    bordered
                    columns={this.state.columns}
                    dataSource={this.state.dataSource}
                    loading={this.state.loadingData}
                    scroll={{ x: "max-content", y: 450 }}
                    size="small"
                    pagination={this.state.pagination}
                    rowKey={this.pk}
                    onChange={this.handleTableChange}
                    title={() => (
                      <Row>
                        <Col span={24}>
                          <Radio.Group
                            options={this.state.datajenis_surat}
                            onChange={(e) => {
                              this.setState(
                                {
                                  filterJenis: e.target.value,
                                },
                                () => {
                                  this.getData();
                                }
                              );
                            }}
                            value={this.state.filterJenis}
                            optionType="button"
                            buttonStyle="solid"
                            style={{ marginBottom: 10 }}
                          />
                        </Col>
                        <Col lg={8} sm={24} xs={24}>
                          <span className="konten-judul">
                            Total {this.state.pagination.total} record
                          </span>
                        </Col>
                        <Col lg={{ span: 8, offset: 8 }} sm={24} xs={24}>
                          <Row>
                            <Col span={6}>
                              <Form.Item
                                name="tahun"
                                rules={[
                                  {
                                    required: true,
                                    message: " harus dipilih",
                                  },
                                ]}
                              >
                                <Select
                                  size={"small"}
                                  options={this.state.yearItems}
                                  showSearch
                                  placeholder="pilih"
                                  optionFilterProp="children"
                                  onChange={(val) => {
                                    this.setState(
                                      {
                                        tahunDipilih: val,
                                      },
                                      () => {
                                        this.getData();
                                      }
                                    );
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={18}>
                              <Form.Item>
                                <Input.Search
                                  size="small"
                                  placeholder="ketikkan pencarian"
                                  onPressEnter={(e) => {
                                    e.preventDefault();
                                  }}
                                  onSearch={(value) => {
                                    this.setState(
                                      {
                                        searchText: value,
                                      },
                                      () => {
                                        this.getData();
                                      }
                                    );
                                  }}
                                  allowClear
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )}
                  />
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
        <Modal
          title={<div style={{ textAlign: "center" }}>{"REVIEW"}</div>}
          centered
          open={this.state.openDetailReview}
          onCancel={() => this.setState({ openDetailReview: false })}
          width={"80%"}
          footer={[]}
        >
          {this.state.suratTugas != null &&
            <Row>
              <Col span={4} className="titik-dua">Status</Col>
              <Col span={20}>
                {this.state.suratTugas[this.state.jenisReview] == 1 &&
                  <Tag color={"default"}>{this.state.suratTugas[this.state.jenisReview + "_nama"]}</Tag>
                }
                {this.state.suratTugas[this.state.jenisReview] == 2 &&
                  <Tag color={"success"}>{this.state.suratTugas[this.state.jenisReview + "_nama"]}</Tag>
                }
                {this.state.suratTugas[this.state.jenisReview] == 3 &&
                  <Tag color={"warning"}>{this.state.suratTugas[this.state.jenisReview + "_nama"]}</Tag>
                }
                {this.state.suratTugas[this.state.jenisReview] == 4 &&
                  <Tag color={"error"}>{this.state.suratTugas[this.state.jenisReview + "_nama"]}</Tag>
                }
              </Col>
              <Col span={4} className="titik-dua">Catatan</Col>
              <Col span={20} dangerouslySetInnerHTML={{ __html: this.state.suratTugas[this.state.jenisReview + "_review"] != null && this.state.suratTugas[this.state.jenisReview + "_review"] != "" ? this.state.suratTugas[this.state.jenisReview + "_review"] : "-" }} />
            </Row>
          }
        </Modal>

        <Modal
          title={
            <div style={{ textAlign: "center", textTransform: "uppercase" }}>
              {this.state.aksi + " " + this.props.configClass.name}
            </div>
          }
          centered
          open={this.state.open}
          onCancel={() => this.setState({ open: false })}
          width={"95%"}
          footer={[]}
        >
          <Spin spinning={this.state.loadingData}>
            <Form
              name="fnotisiinsert"
              // labelCol={{
              //   span: 6,
              // }}
              // wrapperCol={{
              //   span: 18,
              // }}
              layout="vertical"
              // onFinish={this.handleSubmit}
              autoComplete="off"
              ref={this.formRefModal}
            // style={{
            //   maxWidth: 600,
            // }}
            // initialValues={{
            //   remember: true,
            // }}
            >
              <Row>
                {this.state.errMsg != "" &&
                  <Col span={24}>
                    <Alert
                      id="error-alert"
                      message={this.state.errMsg}
                      type="error"
                      showIcon />
                  </Col>
                }
                <Col span={24}>
                  <Form.Item
                    label="NOMOR SURAT"
                    name={"nomor_surat"}
                    rules={[
                      {
                        required: true,
                        message: "harus diisi",
                      },
                    ]}
                  >
                    <Input readOnly />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="JUDUL NOTISI SEMENTARA"
                    name={"nama"}
                    rules={[
                      {
                        required: true,
                        message: "harus diisi",
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => {
                        this.setState({
                          nama: e.target.value
                        })
                      }} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[3, 3]}>
                <Col span={24}>
                  <label
                    for="temuan"
                    class="ant-form-item-required"
                    title="TEMUAN"
                  >
                    FORM TEMUAN
                  </label>
                </Col>
              </Row>


              {this.state.temuan.map((item, idx) => (
                <Form
                  name={"ftemuan" + idx}
                  // labelCol={{
                  //   span: 6,
                  // }}
                  // wrapperCol={{
                  //   span: 18,
                  // }}
                  layout="vertical"
                  onFinish={(values) => this.handleSubmitTemuan(values, idx)}
                  autoComplete="off"
                  // ref={this.formRefTemuan}
                  // style={{
                  //   maxWidth: 600,
                  // }}
                  initialValues={{
                    temuan: item.temuan != undefined && item.temuan != null ? item.temuan : undefined,
                    kondisi: item.kondisi != undefined && item.kondisi != null ? item.kondisi : undefined,
                    kriteria: item.kriteria != undefined && item.kriteria != null ? item.kriteria : undefined,
                    nominal: item.nominal != undefined && item.nominal != null ? item.nominal : undefined,
                    status: item.status != undefined && item.status != null ? item.status : undefined,
                  }}
                >
                  <Row gutter={[3, 3]} style={{ border: "1px solid #ccc", padding: 10, marginBottom: 10 }}>
                    <Col span={24} style={{ fontWeight: "bold" }}>{"DATA TEMUAN KE " + parseFloat(idx + 1)}</Col>
                    <Col span={24}>
                      <Form.Item
                        label="JUDUL TEMUAN"
                        name={"temuan"}
                        rules={[
                          {
                            required: true,
                            message: "harus dipilih",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Pilih"
                          optionFilterProp="children"
                          onChange={(val) => {
                            let temp = [...this.state.temuan];
                            temp[idx]["temuan"] = val;
                            this.setState({
                              temuan: temp,
                            });
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          options={this.state.datatemuan}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="KONDISI"
                        name={"kondisi"}
                        rules={[
                          {
                            required: true,
                            message: "harus diisi",
                          },
                        ]}
                      >
                        <Input
                          onChange={(val) => {
                            let temp = [...this.state.temuan];
                            temp[idx]["kondisi"] = val;
                            this.setState({
                              temuan: temp,
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="KRITERIA"
                        name={"kriteria"}
                        rules={[
                          {
                            required: true,
                            message: "harus diisi",
                          },
                        ]}
                      >
                        <Input
                          onChange={(val) => {
                            let temp = [...this.state.temuan];
                            temp[idx]["kriteria"] = val;
                            this.setState({
                              temuan: temp,
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    {item.sebab.map((itemSebab, idxSebab) => (
                      <Col span={24} key={"sebab" + idx + idxSebab}>
                        <Row gutter={[3, 3]}>
                          <Col span={10}>
                            <Form.Item
                              label="SEBAB"
                              // name={"sebab" + idx + idxSebab}
                              rules={[
                                {
                                  required: true,
                                  message: "harus dipilih",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder="Pilih"
                                optionFilterProp="children"
                                onChange={(val) => {
                                  let temp = [...this.state.temuan];
                                  temp[idx]["sebab"][idxSebab]["kode"] = val;
                                  this.setState({
                                    temuan: temp,
                                  });
                                }}
                                filterOption={(input, option) =>
                                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={this.state.datasebab}
                                value={itemSebab.kode}
                              // defaultValue={itemSebab.kode}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={10}>
                            <Form.Item
                              label="AKIBAT"
                              // name={"akibat" + idx + idxSebab}
                              rules={[
                                {
                                  required: true,
                                  message: "harus diisi",
                                },
                              ]}
                            >
                              <Input
                                value={itemSebab.akibat}
                                onChange={(e) => {
                                  let temp = [...this.state.temuan];
                                  temp[idx]["sebab"][idxSebab]["akibat"] = e.target.value;
                                  this.setState({
                                    temuan: temp,
                                  });
                                }}
                              />
                            </Form.Item>
                          </Col>
                          {idxSebab == item.sebab.length - 1
                            ?
                            <Col span={4} style={{ textAlign: "right" }}>
                              <Form.Item label=" ">
                                <Button
                                  htmlType="button"
                                  size="small"
                                  type="primary"
                                  onClick={() => {
                                    this.addSebabAkibat(idx);
                                  }}
                                >
                                  <PlusOutlined /> tambah sebab akibat
                                </Button>
                              </Form.Item>
                            </Col>
                            :
                            <Col span={4}></Col>
                          }
                        </Row>
                      </Col>
                    ))}
                    {item.rekomendasi.map((itemRekomendasi, idxRekomendasi) => (
                      <>
                        <Col span={10}>
                          <Form.Item
                            label={"REKOMENDASI"}
                            // name={"rekomendasi" + idx + idxRekomendasi}
                            rules={[
                              {
                                required: true,
                                message: "harus dipilih",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Pilih"
                              optionFilterProp="children"
                              onChange={(val) => {
                                let temp = [...this.state.temuan];
                                temp[idx]["rekomendasi"][idxRekomendasi]["kode"] = val;
                                this.setState({
                                  temuan: temp,
                                });
                              }}
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              options={this.state.datarekomendasi}
                              value={itemRekomendasi.kode}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={10}>
                          <Form.Item
                            label={"KATEGORI REKOMENDASI"}
                            // name={"kategori_rekomendasi" + idx + idxRekomendasi}
                            rules={[
                              {
                                required: true,
                                message: "harus dipilih",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Pilih"
                              optionFilterProp="children"
                              onChange={(val) => {
                                let temp = [...this.state.temuan];
                                temp[idx]["rekomendasi"][idxRekomendasi]["kategori"] = val;
                                this.setState({
                                  temuan: temp,
                                });
                              }}
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              options={this.state.datakategori_rekomendasi}
                              value={itemRekomendasi.kategori}
                            />
                          </Form.Item>
                        </Col>
                        {idxRekomendasi == item.rekomendasi.length - 1
                          ?
                          <Col span={4} style={{ textAlign: "right" }}>
                            <Form.Item label=" ">
                              <Button
                                htmlType="button"
                                size="small"
                                type="primary"
                                onClick={() => {
                                  this.addRekomendasi(idx);
                                }}
                              >
                                <PlusOutlined /> tambah rekomendasi
                              </Button>
                            </Form.Item>
                          </Col>
                          :
                          <Col span={4}></Col>
                        }
                      </>
                    ))}
                    <Col span={12}>
                      <Form.Item
                        label="NOMINAL RUPIAH YANG HARUS DIKEMBALIKAN"
                        name={"nominal"}
                        rules={[
                          {
                            required: true,
                            message: "harus diisi",
                          },
                        ]}
                      >
                        <Input
                          onChange={(val) => {
                            let temp = [...this.state.temuan];
                            temp[idx]["nominal"] = val;
                            this.setState({
                              temuan: temp,
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="STATUS TIDAK LANJUT"
                        name={"status"}
                        rules={[
                          {
                            required: true,
                            message: "harus diisi",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Pilih"
                          optionFilterProp="children"
                          onChange={(val) => {
                            let temp = [...this.state.temuan];
                            temp[idx]["status"] = val;
                            this.setState({
                              temuan: temp,
                            });
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          options={[
                            {
                              value: 1,
                              label: "Status 1"
                            },
                            {
                              value: 2,
                              label: "Status 2"
                            }
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                      // label="URAIAN"
                      // name={"uraian" + idx}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "harus diisi",
                      //   },
                      // ]}
                      >
                        Uraian
                      </Form.Item>
                      {this.state.loadingEditor && <Spin size="large" />}
                      <Editor
                        apiKey="31p2mm6w8u0tr9ns1q8np6momklzza9j90vt2wcdjiw4esvy"
                        init={{
                          height: 300,
                        }}
                        onEditorChange={(content, editor) => {
                          let temp = [...this.state.temuan];
                          temp[idx]["uraian"] = content;
                          this.setState({
                            temuan: temp,
                          });
                        }}
                        value={this.state.temuan[idx]["uraian"]}
                        onInit={() => {
                          this.setState({
                            loadingEditor: false,
                          });
                        }}
                      // value={this.state.catatan}
                      />
                    </Col>
                    <Col span={24} style={{ textAlign: "right" }}>
                      <Form.Item label=" ">
                        <Button
                          htmlType="submit"
                          size="small"
                          type="primary"

                        >
                          <SaveOutlined /> simpan temuan
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              ))}
              <div style={{ textAlign: "right" }}>
                <Space>
                  <Form.Item label=" ">
                    <Button
                      htmlType="button"
                      size="small"
                      type="primary"
                      onClick={() => {
                        this.addTemuan();
                      }}
                    >
                      <PlusOutlined /> tambah temuan
                    </Button>
                  </Form.Item>
                </Space>
              </div>


              <Row gutter={[3, 3]}>
                <Col span={24}>
                  <label
                    for="hal"
                    class="ant-form-item-required"
                    title="HAL-HAL YANG PERLU DIPERHATIKAN"
                  >
                    HAL-HAL YANG PERLU DIPERHATIKAN
                  </label>
                </Col>
              </Row>

              {this.state.hal.map((itemHal, idx) => (
                <Form
                  key={"fhal" + idx}
                  name={"fhal" + idx}
                  // labelCol={{
                  //   span: 6,
                  // }}
                  // wrapperCol={{
                  //   span: 18,
                  // }}
                  layout="vertical"
                  onFinish={(values) => this.handleSubmitHal(values, idx)}
                  // autoComplete="off"
                  // ref={this.formRefTemuan}
                  // style={{
                  //   maxWidth: 600,
                  // }}
                  initialValues={{
                    judul: itemHal.judul != undefined && itemHal.judul != null ? itemHal.judul : undefined,
                    kondisi: itemHal.kondisi != undefined && itemHal.kondisi != null ? itemHal.kondisi : undefined,
                    saran: itemHal.saran != undefined && itemHal.saran != null ? itemHal.saran : undefined,
                  }}
                >
                  <Row gutter={[3, 3]} key={"hal" + idx} style={{ border: "1px solid #ccc", padding: 10, marginBottom: 10 }}>
                    <Col span={24} style={{ fontWeight: "bold" }}>{"HAL KE " + parseFloat(idx + 1)}</Col>
                    <Col span={24}>
                      <Form.Item
                        label="JUDUL"
                        name={"judul"}
                        rules={[
                          {
                            required: true,
                            message: "harus dipilih",
                          },
                        ]}
                      >
                        <Input
                          placeholder="input judul"
                          onChange={(val) => {
                            let temp = [...this.state.hal];
                            temp[idx]["judul"] = val;
                            this.setState({
                              hal: temp,
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="KONDISI"
                        name={"kondisi"}
                        rules={[
                          {
                            required: true,
                            message: "harus diisi",
                          },
                        ]}
                      >
                        <Input
                          onChange={(val) => {
                            let temp = [...this.state.hal];
                            temp[idx]["kondisi"] = val;
                            this.setState({
                              hal: temp,
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="SARAN"
                        name={"saran"}
                        rules={[
                          {
                            required: true,
                            message: "harus diisi",
                          },
                        ]}
                      >
                        <Input.TextArea rows={5} />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                      // label="URAIAN"
                      // name={"uraian" + idx}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "harus diisi",
                      //   },
                      // ]}
                      >
                        Uraian
                      </Form.Item>
                      {this.state.loadingEditor && <Spin size="large" />}
                      <Editor
                        apiKey="31p2mm6w8u0tr9ns1q8np6momklzza9j90vt2wcdjiw4esvy"
                        init={{
                          height: 300,
                        }}
                        onEditorChange={(content, editor) => {
                          let temp = [...this.state.hal];
                          temp[idx]["uraian"] = content;
                          this.setState({
                            hal: temp,
                          });
                        }}
                        value={this.state.hal[idx]["uraian"]}
                        onInit={() => {
                          this.setState({
                            loadingEditor: false,
                          });
                        }}
                      // value={this.state.catatan}
                      />
                    </Col>
                    <Col span={24} style={{ textAlign: "right" }}>
                      <Form.Item label=" ">
                        <Button
                          htmlType="submit"
                          size="small"
                          type="primary"

                        >
                          <SaveOutlined /> simpan hal
                        </Button>
                      </Form.Item>
                    </Col>
                    {/* <Col span={24} style={{ textAlign: "right" }}>
                      {
                        idx == this.state.hal.length - 1 ? (
                          <Form.Item label=" ">
                            <Button
                              size="small"
                              type="primary"
                              onClick={() => {
                                this.addHal();
                              }}
                            >
                              <PlusOutlined /> tambah hal
                            </Button>
                          </Form.Item>
                        ) : (
                          ""
                        )
                      }
                    </Col> */}
                  </Row>
                </Form>
              ))}
              <div style={{ textAlign: "right" }}>
                <Space>
                  <Form.Item label=" ">
                    <Button
                      htmlType="button"
                      size="small"
                      type="primary"
                      onClick={() => {
                        this.addHal();
                      }}
                    >
                      <PlusOutlined /> tambah hal
                    </Button>
                  </Form.Item>
                </Space>
              </div>
              <br />



              <Form.Item>
                <Space>

                  <Button htmlType="button" type="primary" title="simpan" onClick={() => this.handleSubmitNotisi()}>
                    <SaveOutlined /> Simpan Notisi
                  </Button>


                  <Button
                    htmlType="button"
                    type="primary"
                    danger
                    title="batalkan"
                    onClick={() => {
                      this.setState({
                        open: false,
                      }, () => {
                        this.resetStatePost();
                      });
                    }}
                  >
                    <CloseSquareOutlined /> Batal
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Spin>
        </Modal>

        <Modal
          title={<div style={{ textAlign: "center" }}>{"REVIEW KARTU TUGAS"}</div>}
          centered
          open={this.state.openReview}
          onCancel={() => this.setState({ openReview: false })}
          width={"90%"}
          footer={[]}
        >
          <Spin spinning={this.state.loadingData}>
            <>
              {this.state.loadingEditor && <Spin size="large" />}
              <Editor
                apiKey="31p2mm6w8u0tr9ns1q8np6momklzza9j90vt2wcdjiw4esvy"
                init={{
                  height: 300,
                }}
                onEditorChange={(content, editor) => {
                  this.setState({
                    catatan: content,
                  });
                }}
                onInit={() => {
                  this.setState({
                    loadingEditor: false,
                  });
                }}
                value={this.state.catatan}
              />
              {this.state.loadingEditor == false &&
                <Row justify="end" style={{ marginTop: 20 }} gutter={[3, 3]}>
                  <Col>
                    <Popconfirm
                      title="yakin menerima kartu tugas?"
                      onConfirm={() => this.prosesReview(2)}
                      okText="Ya"
                      cancelText="Tidak"
                      okButtonProps={{
                        type: "primary",
                        danger: true,
                      }}
                      cancelButtonProps={{
                        type: "primary",
                      }}
                    >
                      <Button
                        type="primary"
                        success
                      >
                        Terima
                      </Button>
                    </Popconfirm>
                  </Col>
                  <Col>
                    <Popconfirm
                      title="yakin menerima kartu tugas dengan catatan?"
                      onConfirm={() => this.prosesReview(3)}
                      okText="Ya"
                      cancelText="Tidak"
                      okButtonProps={{
                        type: "primary",
                        danger: true,
                      }}
                      cancelButtonProps={{
                        type: "primary",
                      }}
                    >
                      <Button
                        type="primary"
                        style={{
                          background: "#FDBD19"
                        }}
                      >
                        Terima dengan Catatan
                      </Button>
                    </Popconfirm>
                  </Col>
                  <Col>
                    <Popconfirm
                      title="yakin menolak kartu tugas?"
                      onConfirm={() => this.prosesReview(4)}
                      okText="Ya"
                      cancelText="Tidak"
                      okButtonProps={{
                        type: "primary",
                        danger: true,
                      }}
                      cancelButtonProps={{
                        type: "primary",
                      }}
                    >
                      <Button
                        type="primary"
                        danger
                      >
                        Tolak
                      </Button>
                    </Popconfirm>
                  </Col>
                </Row>
              }
            </>
          </Spin>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  setConfig,
  ajaxHandler,
  ajaxViewHandler,
})(withRouter(NotisiSementaraHasilAudit));
