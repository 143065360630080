export const audit_univers = {
  router: [
    {
      name: "AUDIT UNIVERS",
      key: "audit_univers",
      path: "/admin/ppbr/audit-univers",
      apiUrl: {
        data: "ppbr/api/audit/universe/",
        preview: "ppbr/api/audit/universe/",
      },
      breadCrumb: [
        {
          label: "PPBR",
          link: "#",
          active: false,
        },
        {
          label: "PENILAIAN KEMATANGAN MR",
          link: "#",
          active: false,
        },
        {
          label: "AUDIT UNIVERS",
          link: "#",
          active: true,
        },
      ],
      component: "datatable2",
    },
  ],
  model: [
    {
      name: "id",
      isPk: true,
      isForm: false,
      isTable: false,
    },
    {
      name: "tahun",
      label: "Tahun",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "auditee",
      label: "Auditee",
      type: "select",
      tableValue: "auditee_nama",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi",
          },
        ],
      },
      isForm: true,
      isTable: true,
      references: {
        apiUrl: "master/api/auditee/",
        value: "id",
        label: "nama"
      }
    },
    {
      name: "asn",
      label: "Asn",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "non_asn",
      label: "Non Asn",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "jumlah_program",
      label: "Jumlah Program",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "jumlah_kegiatan",
      label: "Jumlah Kegiatan",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "jumlah_sub_kegiatan",
      label: "Jumlah Sub Kegiatan",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "anggaran_total",
      label: "Anggaran Total",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "realisasi_anggaran_total",
      label: "Realisasi",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "belanja_modal_jumlah",
      label: "Jumlah Belanja Modal",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "belanja_modal_jumlah_paket",
      label: "Jumlah Paket Belanja Modal",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "realisasi_belanja_modal",
      label: "Realisasi Anggaran Belanja Modal",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "nilai_akip",
      label: "Nilai Akip",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "nilai_ikm",
      label: "Nilai Ikm",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "diperiksa_apip",
      label: "Apip (Tahun Terakhir Diperiksa)",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "diperiksa_apep",
      label: "Apep (Tahun Terakhir Diperiksa)",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },

    {
      name: "temuan_apip",
      label: "Jumlah Temuan Apip",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },

    {
      name: "temuan_apep",
      label: "Jumlah Temuan Apep",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },

    {
      name: "tindak_lanjut_apip",
      label: "Tindak Lanjut Apip",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "tindak_lanjut_apep",
      label: "Tindak Lanjut Apep",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    }


  ],
};
