export const tahun_anggaran = {
  router: [
    {
      name: "PROSES BISNIS",
      key: "proses_bisnis",
      path: "/admin/master/tahun_anggaran",
      apiUrl: {
        data: "master/api/tahun/anggaran/",
        preview: "master/api/tahun/anggaran/",
      },
      breadCrumb: [
        {
          label: "MASTER",
          link: "#",
          active: false,
        },
        {
          label: "TAHUN ANGGARAN",
          link: "#",
          active: false,
        },
      ],
      component: "datatable2",
    },
  ],
  model: [
    {
      name: "id",
      isPk: true,
      isForm: false,
      isTable: false,
    },
    {
      name: "tahun",
      label: "Tahun",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "jumlah_anggaran",
      label: "Jumlah Anggaran",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
  ],
};
