import "../assets/style.css";

import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  Layout,
  Menu,
  Drawer,
  Result,
  Button,
  message,
  notification,
} from "antd";
import { enquireScreen, unenquireScreen } from "enquire-js";
// import 'antd/dist/antd.css';
// import '../assets/style.css';

import "react-perfect-scrollbar/dist/css/styles.css";

import { connect } from "react-redux";
import { setConfig, ajaxViewHandler } from "../store/actions";

import axios from "axios";
import Config from "../Config";

import Cookies from "js-cookie";
import Sider from "../components/AdminSider";
import Header from "../components/AdminHeader";
import Footer from "../components/AdminFooter";

import Home from "../views/Home";
import Forbidden from "../views/Forbidden";

import DataTable1 from "../views/DataTable1.jsx";
import DataTable2 from "../views/DataTable2.jsx";
import DataTable3 from "../views/DataTable3.jsx";
import ResikoAreaPengawasan from "../views/ResikoAreaPengawasan.jsx";

import { jabatan_tugas } from "../models/master/jabatan_tugas";
import { tahun_anggaran } from "../models/master/tahun_anggaran";
import { auditee } from "../models/master/auditee";
import { manajemen_resiko } from "../models/master/manajemen_resiko";
import { strategi_pengawasan } from "../models/master/strategi_pengawasan";
import { jenis_surat } from "../models/master/jenis_surat";
import { tujuan_laporan } from "../models/master/tujuan_laporan";
import { tingkat_resiko } from "../models/master/tingkat_resiko";
import { temuan } from "../models/master/temuan";
import { sebab } from "../models/master/sebab";
import { kategori_rekomendasi } from "../models/master/kategori_rekomendasi";
import { kode_rekomendasi } from "../models/master/kode_rekomendasi";
import { template_pekerjaan } from "../models/master/template_pekerjaan";

import LangkahAudit from "../views/LangkahAudit.jsx";
import JenisPekerjaan from "../views/JenisPekerjaan.jsx";
import TemplatePengujian from "../views/TemplatePengujian.jsx";

import { proses_bisnis } from "../models/ppbr/proses_bisnis";
import { audit_univers } from "../models/ppbr/audit_univers";
import { pbr_strategi_pengawasan } from "../models/ppbr/pbr_strategi_pengawasan";
import { peringkat_prioritas } from "../models/ppbr/peringkat_prioritas";
import { laporan_prioritas } from "../models/ppbr/laporan_prioritas";
import { area_pengawasan_wajib } from "../models/ppbr/area_pengawasan_wajib";

import SuratTugas from "../views/SuratTugas.jsx";
import KartuTugas from "../views/KartuTugas.jsx";

import AnggaranWaktuPelaksanaan from "../views/anggaran_waktu_pelaksanaan/AnggaranWaktuPelaksanaan.jsx";
import FormAnggaranWaktuPelaksanaan from "../views/anggaran_waktu_pelaksanaan/FormAnggaranWaktuPelaksanaan.jsx";

import PernyataanIndependensi from "../views/PernyataanIndependensi.jsx";

import ProgramKerjaAudit from "../views/program_kerja_audit/ProgramKerjaAudit.jsx";
import FormProgramKerjaAudit from "../views/program_kerja_audit/FormProgramKerjaAudit.jsx";

import SupervisiLapangan from "../views/SupervisiLapangan.jsx";
import KertasKerjaAudit from "../views/KertasKerjaAudit.jsx";
import NotisiSementaraHasilAudit from "../views/NotisiSementaraHasilAudit.jsx";
import NotisiAkhirHasilAudit from "../views/NotisiAkhirHasilAudit.jsx";

import ReviewSupervisi from "../views/ReviewSupervisi.jsx";
import LaporanHasilAudit from "../views/LaporanHasilAudit.jsx";
import DaftarPengujianAkhir from "../views/DaftarPengujianAkhir.jsx";

import ProsesTindakLanjut from "../views/ProsesTindakLanjut.jsx";
import LaporanTindakLanjut from "../views/LaporanTindakLanjut.jsx";
import DaftarTindakLanjut from "../views/DaftarTindakLanjut.jsx";

const { Content } = Layout;

const configClass = {
  apiUrl: {
    userProfile: "pengguna/api/admin/recent_user/",
  },
};

class AdminLayout extends React.Component {
  config;

  constructor(props) {
    super(props);
    this.config = new Config();
    this.state = {
      routes: [
        jabatan_tugas, tahun_anggaran, auditee, manajemen_resiko, strategi_pengawasan, jenis_surat, tujuan_laporan,
        tingkat_resiko, temuan, sebab, kategori_rekomendasi, kode_rekomendasi,
        template_pekerjaan,
        proses_bisnis, audit_univers, pbr_strategi_pengawasan, peringkat_prioritas, laporan_prioritas, area_pengawasan_wajib
      ],
    };
  }

  handleCloseDrawer = () => {
    this.props.setConfig({ collapsed: true });
    // console.log('drawer_close', this.props.collapsed);
  };

  componentDidMount() {
    // if (!("Notification" in window)) {
    //     alert("browser tidak support notifikasi, notifikasi chat tidak akan aktif di browser ini");
    // }else if(Notification.permission !== "denied"){
    //  Notification.requestPermission().then(function (permission) {
    //       // If the user accepts, let's create a notification
    //       if (permission === "granted") {
    //         //var notification = new Notification("notifikasi berhasil diaktifkan!");
    //       }
    //     });
    // }
    // this.connect();

    this.enquireHandler = enquireScreen((mobile) => {
      const { isMobile } = this.props;
      if (isMobile !== mobile) {
        this.props.setConfig({
          isMobile: mobile,
        });
        if (mobile) {
          this.props.setConfig({
            collapsed: false,
          });
        }
      }
    });

    // this.getProfile();
  }

  componentWillUnmount() {
    unenquireScreen(this.enquireHandler);
  }

  async getProfile() {
    this.props
      .ajaxViewHandler("get", configClass.apiUrl.userProfile)
      .then(() => {
        const data = this.props.responseMsg.results;
        // console.log('data_profile', data);
        if (data.length > 0) {
          this.props.setConfig({
            username: data[0].username,
            id: data[0].id,
            pp: this.config.get_site_info().logo,
            nama: data[0].nama,
            isSuperadmin: data[0].is_admin,
            isMeja: data[0].is_meja,
            isGroup: data[0].is_group,
            is_jumlah_orang: data[0].is_jumlah_orang,
            jabatan: data[0].jabatan,
          });

          // askForPermissioToReceiveNotifications(data[0].tempatTugas);

          // if(!this.props.switchable){
          //  // this.props.setConfig({
          //  //  isSuperadmin: data[0].is_superuser
          //  // });
          // }
        }
      })
      .catch((response) => {
        console.log("gagal_getprofile", response);
        message.error({
          content: "gagal request data profile, coba lagi",
          key: "toast",
        });
      });
  }

  render() {
    if (!this.props.isLogin) {
      return <Redirect to="/auth" />;
    }
    return (
      <Layout>
        {this.props.isMobile ? (
          <Drawer
            closable={false}
            onClose={this.handleCloseDrawer}
            //visible={!this.props.collapsed}
            open={!this.props.collapsed}
            placement="left"
            // width={200}
          >
            <Sider />
          </Drawer>
        ) : (
          <Sider />
        )}
        <div className="container" style={{ maxWidth: "4000px" }}>
          <Header />
          <Content
            className={
              this.props.collapsed == true
                ? "content collapse-margin"
                : "content"
            }
          >
            <Switch>
              <Route path="/admin" component={Home} exact />
              <Route path="/admin/forbidden" component={Forbidden} exact />
              <Route path="/admin/index" component={Home} />

              {this.state.routes.map((item, idx) =>
                item.router.map((route, idxRoute) => (
                  <Route
                    exact
                    key={route.key}
                    path={route.path}
                    render={(props) =>
                      route.component == "datatable2" ? (
                        <DataTable2
                          models={item.model}
                          configClass={route}
                          {...props}
                        />
                      ) : (
                        route.component == "datatable1"
                        ?
                          <DataTable1
                            models={item.model}
                            configClass={route}
                            {...props}
                          />
                        : 
                          <DataTable3
                            models={item.model}
                            configClass={route}
                            {...props}
                          />
                      )
                    }
                  />
                ))
              )}

              <Route
                    exact
                    key={"langkah-audit"}
                    path={"/admin/master/langkah-audit"}
                    render={(props) =>
                      <LangkahAudit
                        breadCrumb={[
                          {
                            label: "MASTER",
                            link: "#",
                            active: false,
                          },
                          {
                            label: "LANGKAH AUDIT",
                            link: "#",
                            active: true,
                          },
                        ]}  
                        configClass={{
                          name: "Langkah Audit",
                          apiUrl: {
                            data: "master/api/langkah/audit/",
                            preview: "master/api/langkah/audit/",
                            hapus: "master/api/langkah/audit/"
                          }
                        }}                        
                      />
                    }
                  />

              <Route
                    exact
                    key={"jenis-pekerjaan"}
                    path={"/admin/master/jenis-pekerjaan"}
                    render={(props) =>
                      <JenisPekerjaan
                        breadCrumb={[
                          {
                            label: "MASTER",
                            link: "#",
                            active: false,
                          },
                          {
                            label: "JENIS PEKERJAAN",
                            link: "#",
                            active: true,
                          },
                        ]}  
                        configClass={{
                          name: "Jenis Pekerjaan",
                          apiUrl: {
                            data: "master/api/jenis/pekerjaan/",
                            preview: "master/api/jenis/pekerjaan/",
                            hapus: "master/api/jenis/pekerjaan/"
                          }
                        }}                        
                      />
                    }
                  />

              <Route
                    exact
                    key={"template-pengujian"}
                    path={"/admin/master/template-penguji"}
                    render={(props) =>
                      <TemplatePengujian
                        breadCrumb={[
                          {
                            label: "MASTER",
                            link: "#",
                            active: false,
                          },
                          {
                            label: "TEMPLATE PENGUJIAN",
                            link: "#",
                            active: true,
                          },
                        ]}  
                        configClass={{
                          name: "Template Pengujian",
                          apiUrl: {
                            data: "master/api/template/penguji/",
                            preview: "master/api/template/penguji/",
                            hapus: "master/api/template/penguji/"
                          }
                        }}                        
                      />
                    }
                  />

              <Route
                    exact
                    key={"resiko_area_pengawasan"}
                    path={"/admin/ppbr/resiko-area-pengawasan"}
                    render={(props) =>
                      <ResikoAreaPengawasan
                          
                        />
                    }
                  />

              <Route
                    exact
                    key={"surat-tugas"}
                    path={"/admin/ppbr/surat-tugas"}
                    render={(props) =>
                      <SuratTugas
                        breadCrumb={[
                          {
                            label: "PPBR",
                            link: "#",
                            active: false,
                          },
                          {
                            label: "PENILAIAN KEMATANGAN MR",
                            link: "#",
                            active: false,
                          },
                          {
                            label: "SURAT TUGAS",
                            link: "#",
                            active: true,
                          },
                        ]}  
                        configClass={{
                          name: "Surat Tugas",
                          apiUrl: {
                            data: "ppbr/api/surat/tugas/",
                            preview: "ppbr/api/surat/tugas/",
                            hapus: "ppbr/api/surat/tugas/"
                          }
                        }}                        
                      />
                    }
                  />

                  <Route
                    exact
                    key={"kartu-tugas"}
                    path={"/admin/persiapan/kartu-penugasan"}
                    render={(props) =>
                      <KartuTugas
                        breadCrumb={[
                          {
                            label: "PERSIAPAN",
                            link: "#",
                            active: false,
                          },
                          {
                            label: "KARTU PENUGASAN",
                            link: "#",
                            active: true,
                          },
                        ]}  
                        configClass={{
                          name: "Kartu Penugasan",
                          apiUrl: {
                            data: "persiapan/api/kartu/tugas/",
                            preview: "persiapan/api/kartu/tugas/",
                            hapus: "persiapan/api/kartu/tugas/",
                            lampiran: "persiapan/api/kartu/tugas/",
                            review: "persiapan/api/review/",
                          }
                        }}                        
                      />
                    }
                  />

                  <Route
                    exact
                    key={"anggaran-waktu-pelaksanaan"}
                    path={"/admin/persiapan/anggaran-waktu-pelaksanaan"}
                    render={(props) =>
                      <AnggaranWaktuPelaksanaan
                        breadCrumb={[
                          {
                            label: "PERSIAPAN",
                            link: "#",
                            active: false,
                          },
                          {
                            label: "ANGGARAN WAKTU PELAKSANAAN",
                            link: "#",
                            active: true,
                          },
                        ]}  
                        configClass={{
                          name: "Anggaran Waktu Pelaksanaan",
                          apiUrl: {
                            data: "persiapan/api/anggaran/waktu/",
                            preview: "persiapan/api/anggaran/waktu/",
                            hapus: "persiapan/api/anggaran/waktu/",
                            lampiran: "persiapan/api/anggaran/waktu/",
                            review: "persiapan/api/review/",
                          }
                        }}                        
                      />
                    }
                  />

                  <Route
                    exact
                    key={"form-anggaran-waktu-pelaksanaan"}
                    path={"/admin/persiapan/anggaran-waktu-pelaksanaan/form/:id"}
                    render={(props) =>
                      <FormAnggaranWaktuPelaksanaan
                        breadCrumb={[
                          {
                            label: "PERSIAPAN",
                            link: "#",
                            active: false,
                          },
                          {
                            label: "ANGGARAN WAKTU PELAKSANAAN",
                            link: "#",
                            active: false,
                          },
                          {
                            label: "FORM",
                            link: "#",
                            active: true,
                          },
                        ]}  
                        configClass={{
                          name: "Anggaran Waktu Pelaksanaan",
                        }}                        
                      />
                    }
                  />

                  <Route
                    exact
                    key={"pernyataan-independensi"}
                    path={"/admin/persiapan/pernyataan-independensi"}
                    render={(props) =>
                      <PernyataanIndependensi
                        breadCrumb={[
                          {
                            label: "PERSIAPAN",
                            link: "#",
                            active: false,
                          },
                          {
                            label: "PERNYATAAN INDEPENDENSI",
                            link: "#",
                            active: true,
                          },
                        ]}  
                        configClass={{
                          name: "Pernyataan Independensi",
                          apiUrl: {
                            data: "persiapan/api/pernyataan/independensi/",
                            preview: "persiapan/api/pernyataan/independensi/",
                            hapus: "persiapan/api/anggaran/waktu/",
                            lampiran: "persiapan/api/pernyataan/independensi/",
                            review: "persiapan/api/review/",
                          }
                        }}                        
                      />
                    }
                  />

                  <Route
                    exact
                    key={"program-kerja-audit"}
                    path={"/admin/persiapan/pka"}
                    render={(props) =>
                      <ProgramKerjaAudit
                        breadCrumb={[
                          {
                            label: "PERSIAPAN",
                            link: "#",
                            active: false,
                          },
                          {
                            label: "PROGRAM KERJA AUDIT",
                            link: "#",
                            active: true,
                          },
                        ]}  
                        configClass={{
                          name: "Program Kerja Audit",
                          apiUrl: {
                            data: "persiapan/api/program/kerja/audit/",
                            preview: "persiapan/api/program/kerja/audit/",
                            hapus: "persiapan/api/program/kerja/audit/",
                            lampiran: "persiapan/api/program/kerja/audit/",
                            review: "persiapan/api/review/",
                          }
                        }}                        
                      />
                    }
                  />

                  <Route
                    exact
                    key={"form-program-kerja-audit"}
                    path={"/admin/persiapan/pka/form/:id?"}
                    render={(props) =>
                      <FormProgramKerjaAudit
                        breadCrumb={[
                          {
                            label: "PERSIAPAN",
                            link: "#",
                            active: false,
                          },
                          {
                            label: "PROGRAM KERJA AUDIT",
                            link: "#",
                            active: false,
                          },
                          {
                            label: "FORM",
                            link: "#",
                            active: true,
                          },
                        ]}  
                        configClass={{
                          name: "Program Kerja Audit",
                          apiUrl: {
                            preview: "persiapan/api/program/kerja/audit/",
                          }
                        }}                        
                      />
                    }
                  />

                  <Route
                    exact
                    key={"supervisi-lapangan"}
                    path={"/admin/pelaksanaan/supervisi-lapangan"}
                    render={(props) =>
                      <SupervisiLapangan
                        breadCrumb={[
                          {
                            label: "PELAKSANAAN",
                            link: "#",
                            active: false,
                          },
                          {
                            label: "SUPERVISI LAPANGAN",
                            link: "#",
                            active: true,
                          },
                        ]}  
                        configClass={{
                          name: "Supervisi Lapangan",
                          apiUrl: {
                            data: "pelaksanaan/api/supervisi/lapangan/",
                            preview: "pelaksanaan/api/supervisi/lapangan/",
                            hapus: "pelaksanaan/api/supervisi/lapangan/",
                            lampiran: "pelaksanaan/api/supervisi/lapangan/",
                            review: "persiapan/api/review/",
                          }
                        }}                        
                      />
                    }
                  />

                  <Route
                    exact
                    key={"kertas-kerja-audit"}
                    path={"/admin/pelaksanaan/kka"}
                    render={(props) =>
                      <KertasKerjaAudit
                        breadCrumb={[
                          {
                            label: "PELAKSANAAN",
                            link: "#",
                            active: false,
                          },
                          {
                            label: "KERTAS KERJA AUDIT",
                            link: "#",
                            active: true,
                          },
                        ]}  
                        configClass={{
                          name: "Kertas Kerja Audit",
                          apiUrl: {
                            data: "pelaksanaan/api/kertas/kerja/audit/",
                            preview: "pelaksanaan/api/kertas/kerja/audit/",
                            hapus: "pelaksanaan/api/kertas/kerja/audit/",
                            lampiran: "pelaksanaan/api/lampiran/",
                            review: "persiapan/api/review/",
                          }
                        }}                        
                      />
                    }
                  />

                  <Route
                    exact
                    key={"notisi-sementara-audit"}
                    path={"/admin/pelaksanaan/notisi-sementara-hasil-audit"}
                    render={(props) =>
                      <NotisiSementaraHasilAudit
                        breadCrumb={[
                          {
                            label: "PELAKSANAAN",
                            link: "#",
                            active: false,
                          },
                          {
                            label: "NOTISI SEMENTARA HASIL AUDIT",
                            link: "#",
                            active: true,
                          },
                        ]}  
                        configClass={{
                          name: "Notisi Sementara Hasil Audit",
                          apiUrl: {
                            data: "pelaksanaan/api/notisi/sementara/",
                            preview: "pelaksanaan/api/notisi/sementara/",
                            hapus: "pelaksanaan/api/notisi/sementara/",
                            lampiran: "pelaksanaan/api/notisi/sementara/",
                            review: "persiapan/api/review/",
                            temuan: "pelaksanaan/api/temuan/",
                            hal: "pelaksanaan/api/hal/",

                          }
                        }}                        
                      />
                    }
                  />

                  <Route
                    exact
                    key={"notisi-akhir-audit"}
                    path={"/admin/pelaksanaan/notisi-akhir-hasil-audit"}
                    render={(props) =>
                      <NotisiAkhirHasilAudit
                        breadCrumb={[
                          {
                            label: "PELAKSANAAN",
                            link: "#",
                            active: false,
                          },
                          {
                            label: "NOTISI AKHIR HASIL AUDIT",
                            link: "#",
                            active: true,
                          },
                        ]}  
                        configClass={{
                          name: "Notisi Akhir Hasil Audit",
                          apiUrl: {
                            data: "pelaksanaan/api/notisi/akhir/",
                            preview: "pelaksanaan/api/notisi/akhir/",
                            hapus: "pelaksanaan/api/notisi/akhir/",
                            lampiran: "pelaksanaan/api/notisi/akhir/",
                            review: "persiapan/api/review/",
                            temuan: "pelaksanaan/api/temuan/",
                            hal: "pelaksanaan/api/hal/",
                          }
                        }}                        
                      />
                    }
                  />

                  <Route
                    exact
                    key={"review-supervisi"}
                    path={"/admin/pelaporan/review-sheet-supervisi"}
                    render={(props) =>
                      <ReviewSupervisi
                        breadCrumb={[
                          {
                            label: "PELAPORAN",
                            link: "#",
                            active: false,
                          },
                          {
                            label: "REVIEW SHEET SUPERVISI",
                            link: "#",
                            active: true,
                          },
                        ]}  
                        configClass={{
                          name: "Review Sheet Supervisi",
                          apiUrl: {
                            data: "pelaporan/api/reviu/",
                            preview: "pelaporan/api/reviu/",
                            hapus: "pelaporan/api/reviu/",
                            lampiran: "pelaporan/api/reviu/",
                            review: "persiapan/api/review/",
                          }
                        }}                        
                      />
                    }
                  />

                  <Route
                    exact
                    key={"laporan-hasil-audit"}
                    path={"/admin/pelaporan/laporan-hasil-audit"}
                    render={(props) =>
                      <LaporanHasilAudit
                        breadCrumb={[
                          {
                            label: "PELAPORAN",
                            link: "#",
                            active: false,
                          },
                          {
                            label: "LAPORAN HASIL AUDIT",
                            link: "#",
                            active: true,
                          },
                        ]}  
                        configClass={{
                          name: "Laporan Hasil Audit",
                          apiUrl: {
                            data: "pelaporan/api/laporan/audit/",
                            preview: "pelaporan/api/laporan/audit/",
                            hapus: "pelaporan/api/laporan/audit/",
                            lampiran: "pelaporan/api/laporan/audit/",
                            review: "persiapan/api/review/",
                          }
                        }}                        
                      />
                    }
                  />

                  <Route
                    exact
                    key={"daftar-pengujian-akhir"}
                    path={"/admin/pelaporan/daftar-pengujian-akhir"}
                    render={(props) =>
                      <DaftarPengujianAkhir
                        breadCrumb={[
                          {
                            label: "PELAPORAN",
                            link: "#",
                            active: false,
                          },
                          {
                            label: "DAFTAR PENGUJIAN AKHIR",
                            link: "#",
                            active: true,
                          },
                        ]}  
                        configClass={{
                          name: "Daftar Pengujian Akhir",
                          apiUrl: {
                            data: "pelaporan/api/pengujian/akhir/",
                            preview: "pelaporan/api/pengujian/akhir/",
                            hapus: "pelaporan/api/pengujian/akhir/",
                            lampiran: "pelaporan/api/pengujian/akhir/",
                            review: "persiapan/api/review/",
                          }
                        }}                        
                      />
                    }
                  />

                  <Route
                    exact
                    key={"proses-tindak-lanjut"}
                    path={"/admin/tindaklanjut/proses-tindak-lanjut"}
                    render={(props) =>
                      <ProsesTindakLanjut
                        breadCrumb={[
                          {
                            label: "TINDAK LANJUT",
                            link: "#",
                            active: false,
                          },
                          {
                            label: "PROSES TINDAK LANJUT",
                            link: "#",
                            active: true,
                          },
                        ]}  
                        configClass={{
                          name: "Proses Tindak Lanjut",
                          apiUrl: {
                            data: "tindak/lanjut/api/laporan/audit/",
                            preview: "tindak/lanjut/api/laporan/audit/",
                            hapus: "tindak/lanjut/api/laporan/audit/",
                            lampiran: "tindak/lanjut/api/laporan/audit/",
                            review: "persiapan/api/review/",
                          }
                        }}                        
                      />
                    }
                  />

                  <Route
                    exact
                    key={"laporan-tindak-lanjut"}
                    path={"/admin/tindaklanjut/laporan-tindak-lanjut"}
                    render={(props) =>
                      <LaporanTindakLanjut
                        breadCrumb={[
                          {
                            label: "TINDAK LANJUT",
                            link: "#",
                            active: false,
                          },
                          {
                            label: "LAPORAN TINDAK LANJUT",
                            link: "#",
                            active: true,
                          },
                        ]}  
                        configClass={{
                          name: "Laporan Tindak Lanjut",
                          apiUrl: {
                            data: "tindak/lanjut/api/tindak/lanjut/",
                            preview: "tindak/lanjut/api/tindak/lanjut/",
                            hapus: "tindak/lanjut/api/tindak/lanjut/",
                            lampiran: "tindak/lanjut/api/tindak/lanjut/",
                            review: "persiapan/api/review/",
                          }
                        }}                        
                      />
                    }
                  />

                  <Route
                    exact
                    key={"daftar-tindak-lanjut"}
                    path={"/admin/tindaklanjut/daftar-tindak-lanjut"}
                    render={(props) =>
                      <DaftarTindakLanjut
                        breadCrumb={[
                          {
                            label: "TINDAK LANJUT",
                            link: "#",
                            active: false,
                          },
                          {
                            label: "DAFTAR TINDAK LANJUT",
                            link: "#",
                            active: true,
                          },
                        ]}  
                        configClass={{
                          name: "Daftar Tindak Lanjut",
                          apiUrl: {
                            data: "tindak/lanjut/api/jawaban/auditee/",
                            preview: "tindak/lanjut/api/jawaban/auditee/",
                            hapus: "tindak/lanjut/api/jawaban/auditee/",
                            lampiran: "tindak/lanjut/api/jawaban/auditee/",
                            review: "persiapan/api/review/",
                          }
                        }}                        
                      />
                    }
                  />

              <Route
                path=""
                render={() => (
                  <Result
                    status="404"
                    title="404"
                    subTitle="Maaf, halaman tidak ditemukan."
                  />
                )}
              />
            </Switch>
          </Content>
          {/*<Footer />*/}
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  setConfig,
  ajaxViewHandler,
})(AdminLayout);
