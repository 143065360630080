import React, { Component }  from 'react';

class Config{
	/*
	jenis layanan: 1 home care, ambulance 2, laboratorium 3, obat 4
	*/
	get_site_info(){
		return {
			name: process.env.REACT_APP_NAME,
			logo: process.env.PUBLIC_URL + "/" +process.env.REACT_APP_ICON,
			logo_landscape: process.env.PUBLIC_URL + "/" +process.env.REACT_APP_ICON,
			description: process.env.REACT_APP_NAME
		}
	}

	get_server_url(){
		return process.env.REACT_APP_API_PATH;
	}
	
	get_base_url(){
		return process.env.REACT_APP_API_PATH;
	}

	get_web_socket(){
		return 'wss://';
	}

	get_cookie_name(){
		return process.env.REACT_APP_COOKIE_NAME;
	}

	stripTags(teks){
		return teks.replace(/(<([^>]+)>)/ig, "");//strip tags html
	}

	escape_str(value){
		return(
			<div>
				{value.includes("https://") || value.includes("http://") || value.includes("www.")
					?
						<div>
								<a href={value} target="_blank">{value}</a>
						</div>
					:
						value
				}
			</div>
		);
	}
}
export default Config
