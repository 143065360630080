import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Layout, Menu, Avatar, Space, BackTop, Drawer } from "antd";
import {
  DashboardOutlined,
  AppstoreOutlined,
  ContainerOutlined,
  UnorderedListOutlined,
  CarryOutOutlined
} from "@ant-design/icons";
import Cookies from "js-cookie";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { setConfig, ajaxViewHandler } from "../store/actions";

import Config from "../Config";

const { SubMenu } = Menu;
const { Sider } = Layout;

const configClass = {
  apiUrl: {
    sider: "",
  },
};

class AdminSider extends PureComponent {
  config;

  constructor(props) {
    super(props);
    this.config = new Config();

    this.state = {
      menuAktif: "",
      menuExpand: "",
      openKeys: [],

      showMenu: true,

      menuItems: [
        {
          key: 'dashboard',
          icon: <DashboardOutlined />,
          label: (
            <Link to="/">
              Dashboard
            </Link>
          )
        },
        {
          key: 'master',
          icon: <AppstoreOutlined />,
          label: 'Master',
          children: [
            {
              key: 'jabatan_tugas',
              icon: null,
              label: (
                <Link to="/admin/master/jabatan-tugas">
                  Jabatan Tugas
                </Link>
              )
            },
            {
              key: 'tahun_anggaran',
              icon: null,
              label: (
                <Link to="/admin/master/tahun_anggaran">
                  Tahun Anggaran
                </Link>
              )
            },
            {
              key: 'auditee',
              icon: null,
              label: (
                <Link to="/admin/master/auditee">
                  Auditee
                </Link>
              )
            },
            {
              key: 'manajemen_resiko',
              icon: null,
              label: (
                <Link to="/admin/master/manajemen-resiko">
                  Manajemen Resiko
                </Link>
              )
            },
            {
              key: 'mstrategi_pengawasan',
              icon: null,
              label: (
                <Link to="/admin/master/strategi-pengawasan">
                  Strategi Pengawasan
                </Link>
              )
            },
            {
              key: 'jenis_surat',
              icon: null,
              label: (
                <Link to="/admin/master/jenis-surat">
                  Jenis Surat
                </Link>
              )
            },
            {
              key: 'tujuan_laporan',
              icon: null,
              label: (
                <Link to="/admin/master/tujuan-laporan">
                  Tujuan Laporan
                </Link>
              )
            },
            {
              key: 'tingkat_resiko',
              icon: null,
              label: (
                <Link to="/admin/master/tingkat-resiko">
                  Tingkat Resiko
                </Link>
              )
            },
            {
              key: 'temuan',
              icon: null,
              label: (
                <Link to="/admin/master/temuan">
                  Temuan
                </Link>
              )
            },
            {
              key: 'sebab',
              icon: null,
              label: (
                <Link to="/admin/master/sebab">
                  Sebab
                </Link>
              )
            },
            {
              key: 'kategori_rekomendasi',
              icon: null,
              label: (
                <Link to="/admin/master/kategori-rekomendasi">
                  Kategori Rekomendasi
                </Link>
              )
            },
            {
              key: 'kode_rekomendasi',
              icon: null,
              label: (
                <Link to="/admin/master/kode-rekomendasi">
                  Kode Rekomendasi
                </Link>
              )
            },
            {
              key: 'langkah_audit',
              icon: null,
              label: (
                <Link to="/admin/master/langkah-audit">
                  Langkah Audit
                </Link>
              )
            },
            {
              key: 'template_pekerjaan',
              icon: null,
              label: (
                <Link to="/admin/master/template-pekerjaan">
                  Template Pekerjaan
                </Link>
              )
            },
            {
              key: 'jenis_pekerjaan',
              icon: null,
              label: (
                <Link to="/admin/master/jenis-pekerjaan">
                  Jenis Pekerjaan
                </Link>
              )
            },
            {
              key: 'template_penguji',
              icon: null,
              label: (
                <Link to="/admin/master/template-penguji">
                  Template Penguji
                </Link>
              )
            },
          ]
        },
        {
          key: 'ppbr',
          icon: <ContainerOutlined />,
          label: 'PPBR',
          children: [
            {
              key: 'prosesbisnis',
              icon: null,
              label: (
                <Link to="/admin/ppbr/proses-bisnis">
                  Proses Bisnis
                </Link>
              )
            },
            {
              key: 'auditunivers',
              icon: null,
              label: (
                <Link to="/admin/ppbr/audit-univers">
                  Audit Univers
                </Link>
              )
            },
            {
              key: 'strategipengawasan',
              icon: null,
              label: (
                <Link to="/admin/ppbr/strategi-pengawasan">
                  Strategi Pengawasan
                </Link>
              )
            },
            {
              key: 'resikoareapengawasan',
              icon: null,
              label: (
                <Link to="/admin/ppbr/resiko-area-pengawasan">
                  Resiko Area Pengawasan
                </Link>
              )
            },
            {
              key: 'peringkatprioritasresikoareapengawasan',
              icon: null,
              label: (
                <Link to="/admin/ppbr/peringkat-prioritas-risiko-area-pengawasan">
                  Peringkat Prioritas Risiko Area Pengawasan
                </Link>
              )
            },
            {
              key: 'laporanprioritas',
              icon: null,
              label: (
                <Link to="/admin/ppbr/laporan-prioritas">
                  Laporan Prioritas
                </Link>
              )
            },
            {
              key: 'area-pengawsan-wajib',
              icon: null,
              label: (
                <Link to="/admin/ppbr/area-pengawasan-wajib">
                  Area Pengawasan Wajib
                </Link>
              )
            },
            {
              key: 'surat-tugas',
              icon: null,
              label: (
                <Link to="/admin/ppbr/surat-tugas">
                  Surat Tugas
                </Link>
              )
            }
          ]
        },
        {
          key: 'persiapan',
          icon: <UnorderedListOutlined />,
          label: 'Persiapan',
          children: [
            {
              key: 'kartupenugasan',
              icon: null,
              label: (
                <Link to="/admin/persiapan/kartu-penugasan">
                  Kartu Penugasan
                </Link>
              )
            },
            {
              key: 'anggaranwaktupelaksanaan',
              icon: null,
              label: (
                <Link to="/admin/persiapan/anggaran-waktu-pelaksanaan">
                  Anggaran Waktu Pelaksanaan
                </Link>
              )
            },
            {
              key: 'pernyataanindependensi',
              icon: null,
              label: (
                <Link to="/admin/persiapan/pernyataan-independensi">
                  Pernyataan Independensi
                </Link>
              )
            },
            {
              key: 'pka',
              icon: null,
              label: (
                <Link to="/admin/persiapan/pka">
                  Program Kerja Audit, (PKA)
                </Link>
              )
            },

          ]
        },
        {
          key: 'pelaksanaan',
          icon: <CarryOutOutlined />,
          label: 'Pelaksanaan',
          children: [
            {
              key: 'supervisilapangan',
              icon: null,
              label: (
                <Link to="/admin/pelaksanaan/supervisi-lapangan">
                  Supervisi Lapangan
                </Link>
              )
            },
            {
              key: 'kka',
              icon: null,
              label: (
                <Link to="/admin/pelaksanaan/kka">
                  Kerta Kerja Audit (KKA)
                </Link>
              )
            },
            {
              key: 'notisisementarahasilaudit',
              icon: null,
              label: (
                <Link to="/admin/pelaksanaan/notisi-sementara-hasil-audit">
                  Notisi Sementara Hasil Audit
                </Link>
              )
            },
            {
              key: 'notisiakhirhasilaudit',
              icon: null,
              label: (
                <Link to="/admin/pelaksanaan/notisi-akhir-hasil-audit">
                  Notisi Akhir Hasil Audit
                </Link>
              )
            },
          ]
        },
        {
          key: 'pelaporan',
          icon: <CarryOutOutlined />,
          label: 'Pelaporan',
          children: [
            {
              key: 'reviewsheetsupervisi',
              icon: null,
              label: (
                <Link to="/admin/pelaporan/review-sheet-supervisi">
                  Review Sheet Supervisi
                </Link>
              )
            },
            {
              key: 'laphasilaudit',
              icon: null,
              label: (
                <Link to="/admin/pelaporan/laporan-hasil-audit">
                  Laporan Hasil Audit
                </Link>
              )
            },
            {
              key: 'daftarpengujuanakhir',
              icon: null,
              label: (
                <Link to="/admin/pelaporan/daftar-pengujian-akhir">
                  Daftar Pengujian Akhir
                </Link>
              )
            },
          ]
        },
        {
          key: 'tindaklanjut',
          icon: <CarryOutOutlined />,
          label: 'Tindak Lanjut',
          children: [
            {
              key: 'prosestindaklanjut',
              icon: null,
              label: (
                <Link to="/admin/tindaklanjut/proses-tindak-lanjut">
                  Proses Tindak Lanjut
                </Link>
              )
            },
            {
              key: 'laporantindaklanjut',
              icon: null,
              label: (
                <Link to="/admin/tindaklanjut/laporan-tindak-lanjut">
                  Laporan Tindak Lanjut
                </Link>
              )
            },
            {
              key: 'daftartindaklanjut',
              icon: null,
              label: (
                <Link to="/admin/tindaklanjut/daftar-tindak-lanjut">
                  Daftar Tindak Lanjut
                </Link>
              )
            },
          ]
        }
      ]
    };
  }

  componentDidMount() {
    //this.getInfoSidebar();
  }
  onMenuExpand = (listOpen) => {
    if (listOpen.length == 1) {
      console.log("kode1");
      this.setState({
        menuExpand: listOpen[0],
      });
    } else {
      // console.log('kode2', listOpen[listOpen.length-1])
      this.setState({
        menuExpand: listOpen[listOpen.length - 1],
      });
    }
    // if (selectionToolIsDisable) {
    //   const openedKeyIndex = openKeys.indexOf('menu1')
    //     if (openedKeyIndex !== -1) {
    //         const _openKeys = [...openKeys]
    //         _openKeys.splice(openedKeyIndex, 1)
    //         setOpenKeys(_openKeys)
    //     }
    // } else {
    //     setOpenKeys([...openKeys, 'menu1'])
    // }
    // this.setState({
    //   defaultOpenKeys: ["mn_task"]
    // })
  };

  notifyMe(msg) {
    // Let's check if the browser supports notifications
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === "granted") {
      // If it's okay let's create a notification
      var notification = new Notification(msg);
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          var notification = new Notification(msg);
        }
      });
    }

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them any more.
  }

  async getInfoSidebar() {
    this.props
      .ajaxViewHandler("get", configClass.apiUrl.sider)
      .then(() => {
        const data = this.props.responseMsg.results[0];
        if (this.props.isSuccess) {
          /*
          this.setState({
            logoDesa: data.logo!=null&&data.logo!=''?data.logo:process.env.PUBLIC_URL + "/logo_landscape.png",
            namaDesa: data.name,
          });
          */
        } else {
          /*
          this.setState({
            namaDesa: 'gagal menampilkan info desa',
          });
          */
        }
      })
      .catch((response) => {
        /*
        this.setState({
            namaDesa: 'gagal menampilkan info desa',
        });
        */
      });
  }

  render() {
    return (
      <PerfectScrollbar>
        <Helmet>
          <title>
            {"admin | " +
              (this.props.tempatTugas != undefined
                ? this.props.tempatTugas
                : this.config.get_site_info().name)}
          </title>
        </Helmet>
        <Sider
          breakpoint="lg"
          // width={this.props.isMobile ? "100%" : 256}
          trigger={null}
          collapsible
          collapsed={this.props.collapsed}
          className="sider"
          /*
                onCollapse={(collapsed, type) => {
                  this.props.setConfig({
                    collapsed: collapsed
                  });
                }}
                */
          /*
                onBreakpoint={
                  console.log('break')
                  //!this.props.isMobile && this.props.setConfig({collapsed:!this.props.collapsed})
                }
                */
        >
          <div className="brand">
            <div className="logo">
              <img alt="logo" src={this.config.get_site_info().logo} />
              {!this.props.collapsed && <h1>{this.config.get_site_info().name}</h1>}
            </div>
          </div>
          <Menu
            mode="inline"
            defaultSelectedKeys={this.state.menuAktif}
            className="sidebar"
            onOpenChange={this.onMenuExpand}
            defaultOpenKeys={this.state.menuExpand}
            openKeys={[this.state.menuExpand]}
            items={this.state.menuItems}
            theme={"light"}
          />
        </Sider>
      </PerfectScrollbar>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, { setConfig, ajaxViewHandler })(
  AdminSider
);
