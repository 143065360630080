export const peringkat_prioritas = {
  router: [
    {
      name: "PERINGKAT RESIKO AREA PENGAWASAN",
      key: "peringkat_prioritas",
      path: "/admin/ppbr/peringkat-prioritas-risiko-area-pengawasan",
      apiUrl: {
        data: "ppbr/api/peringkat/perioritas/",
        preview: "ppbr/api/peringkat/perioritas/",
      },
      breadCrumb: [
        {
          label: "PPBR",
          link: "#",
          active: false,
        },
        {
          label: "PERENCANAAN PENGAWASAN",
          link: "#",
          active: false,
        },
        {
          label: "PERINGKAT RESIKO AREA PENGAWASAN",
          link: "#",
          active: true,
        },
      ],
      component: "datatable3",
    },
  ],
  model: [
    {
      name: "id",
      isPk: true,
      isForm: false,
      isTable: false,
    },
    {
      name: "auditee_nama",
      label: "Area Pengawasan (Auditable Unit)",
      isForm: false,
      isTable: true,
      type: "label",
    },
    {
      name: "tahun",
      label: "Tahun",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: false,
    },
    {
      name: "bobot_resiko",
      label: "Bobot Inherent Risk (%)",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "nilai",
      label: "Nilai Inherent Risk",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "skala",
      label: "Skala Inherent Risk",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
  ],
};
