export const pertimbangan8 = {
  router: [
    {
      name: "TAHUN TERAKHIR DIPERIKSA, TEMUAN DAN TINDAKLANJUT",
      key: "strategi_pengawasan_pb",
      path: "/admin/ppbr/strategi-pengawasan",
      apiUrl: {
        data: "ppbr/api/resiko/area/delapan/",
        preview: "ppbr/api/resiko/area/delapan/",
      },
      breadCrumb: [
        {
          label: "PPBR",
          link: "#",
          active: false,
        },
        {
          label: "PENILAIAN KEMATANGAN MR",
          link: "#",
          active: false,
        },
        {
          label: "STRATEGI PENGAWASAN",
          link: "#",
          active: true,
        },
      ],
      component: "datatable3",
    },
  ],
  model: [
    {
      name: "id",
      isPk: true,
      isForm: false,
      isTable: false,
    },
    {
      name: "auditee_nama",
      label: "Area Pengawasan (Auditable Unit)",
      isForm: false,
      isTable: true,
      type: "label",
    },
    {
      name: "tahun",
      label: "Tahun",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: false,
    },
    {
      name: "diperiksa_apip",
      label: "Diperiksa APIP",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "diperiksa_apip_skala",
      label: "APIP Skala",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "diperiksa_apep",
      label: "Diperiksa APEP",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "diperiksa_apep_skala",
      label: "APEP Skala",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "temuan_apip",
      label: "Temuan APIP",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "temuan_apip_skala",
      label: "Temuan APIP Skala",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "temuan_apep",
      label: "Temuan APEP",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "temuan_apep_skala",
      label: "Temuan APEP Skala",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "tindak_lanjut_apip",
      label: "Tindak Lanjut APIP",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "tindak_lanjut_apip_skala",
      label: "Tindak Lanjut APIP Skala",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "tindak_lanjut_apep",
      label: "Tindak Lanjut APEP",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "tindak_lanjut_apep_skala",
      label: "Tindak Lanjut APEP Skala",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
  ],
};
