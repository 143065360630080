export const pbr_strategi_pengawasan = {
  router: [
    {
      name: "STRATEGI PENGAWASAN",
      key: "strategi_pengawasan_pb",
      path: "/admin/ppbr/strategi-pengawasan",
      apiUrl: {
        data: "ppbr/api/strategi/pengawasan/",
        preview: "ppbr/api/strategi/pengawasan/",
      },
      breadCrumb: [
        {
          label: "PPBR",
          link: "#",
          active: false,
        },
        {
          label: "PENILAIAN KEMATANGAN MR",
          link: "#",
          active: false,
        },
        {
          label: "STRATEGI PENGAWASAN",
          link: "#",
          active: true,
        },
      ],
      component: "datatable3",
    },
  ],
  model: [
    {
      name: "id",
      isPk: true,
      isForm: false,
      isTable: false,
    },
    {
      name: "auditee_nama",
      label: "Area Pengawasan (Auditable Unit)",
      isForm: false,
      isTable: true,
      type: "label",
    },
    {
      name: "tahun",
      label: "Tahun",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: false,
    },
    {
      name: "bobot",
      label: "Pembobotan RR",
      type: "number",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi angka",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "mr",
      label: "Kematangan MR",
      type: "select",
      tableValue: "mr",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi",
          },
        ],
      },
      isForm: true,
      isTable: true,
      references: {
        apiUrl: "master/api/manajemen/resiko/",
        value: "id",
        label: "nama"
      }
    },
    {
      name: "strategi_pengawasan",
      label: "Strategi Pengawasan",
      type: "select",
      tableValue: "strategi_pengawasan",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi",
          },
        ],
      },
      isForm: true,
      isTable: true,
      references: {
        apiUrl: "master/api/strategi/pengawasan/",
        value: "id",
        label: "nama"
      }
    },
    {
      name: "keterangan",
      label: "Keterangan",
      type: "textarea",
      tableTipe: "deskripsi",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
  ],
};
