import React, { PureComponent } from "react";
import { Redirect, Link } from "react-router-dom";
import { withRouter } from "react-router";
import {
  Button,
  Row,
  Col,
  Input,
  Layout,
  Form,
  Alert,
  Modal,
  message,
  Switch,
  Tabs,
  notification,
  Spin,
  Pagination,
  Tag,
  Breadcrumb,
  Card,
  Space,
  Table,
  Popconfirm,
  Skeleton,
  Select,
  InputNumber,
  Radio,
  Checkbox,
  DatePicker,
} from "antd";
import {
  TeamOutlined,
  SearchOutlined,
  PlusOutlined,
  FormOutlined,
  CloseSquareOutlined,
  ExclamationCircleOutlined,
  DiffOutlined,
  RedoOutlined,
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
  SaveOutlined,
  BookOutlined
} from "@ant-design/icons";
// import "@ant-design/compatible/assets/index.css";
import Config from "../../Config";
import { Helmet } from "react-helmet";

import { Editor } from "@tinymce/tinymce-react";

import { connect } from "react-redux";
import { setConfig, ajaxHandler, ajaxViewHandler } from "../../store/actions";

import moment from "moment";

import axios from "axios";
import Cookies from "js-cookie";

const configClass = {
  apiUrl: {
    template: "master/api/jenis/pekerjaan/",
    clone: "persiapan/api/anggaran/waktu/",
    disusun_oleh: "pengguna/api/pengguna/",
    disetujui_oleh: "pengguna/api/pengguna/",
    mengetahui: "pengguna/api/pengguna/",
  }
}
class FormAnggaranWaktuPelaksanaan extends PureComponent {
  config;
  timeoutGetQr;
  timeOutPushQr;
  formRef = React.createRef();
  formRefAnggaran = React.createRef();
  focusComponent = "";
  pk = "";

  constructor(props) {
    super(props);
    this.config = new Config();
    this.state = {
      tambah: false,
      aksi: "tambah",
      method: "post",
      dataClone: null,
      agendas: [],
      is_success: false,
      errMsg: "",
      errTitle: "",
    };
  }

  async getMasterList(stateName, url, isNext = false) {
    this.setState({
      ["loading" + stateName]: !isNext,
      ["loadingNext" + stateName]: isNext,
      ["loadingSearch" + stateName]: isNext,
    });
    this.props
      .ajaxViewHandler("get", url)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (data.count != undefined) {
            let newState = null;
            if (
              this.state["data" + stateName] == undefined ||
              this.state["data" + stateName] == null
            ) {
              newState = data;
            } else {
              if (data.previous == null) {
                //first data
                newState = data;
              } else {
                const oldState = this.state["data" + stateName];
                newState = {
                  count: data.count,
                  next: data.next,
                  previous: data.previous,
                  results: [...oldState.results, ...data.results],
                };
              }
            }
            // console.log('state_name', destination.stateDestination);
            // console.log('state_value', newState);
            this.setState(
              {
                ["data" + stateName]: newState,
              },
              () => {
                if (stateName == "jenis_surat") {
                  let temp = [];
                  data.results.map((item, idx) => {
                    temp = [
                      ...temp,
                      {
                        value: item.id,
                        label: item.nama,
                      },
                    ];
                  });
                  this.setState({
                    ["data" + stateName]: temp,
                  });
                }
              }
            );
          } else if (data.results != undefined) {
            this.setState({
              ["data" + stateName]: data.results,
            });
          } else {
            if (Array.isArray(data)) {
              this.setState({
                ["data" + stateName]: data,
              });
            } else {
              this.setState({
                ["data" + stateName]: [data],
              });
            }
          }
        } else {
          console.log(data);
        }
        this.setState({
          ["loading" + stateName]: false,
          ["loadingNext" + stateName]: false,
          ["loadingSearch" + stateName]: false,
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          ["loading" + stateName]: false,
          ["loadingNext" + stateName]: false,
          ["loadingSearch" + stateName]: false,
        });
      });
  }

  generateYears = () => {
    var start = 2020;
    var end = new Date().getFullYear() + 3;
    var items = [];
    for (var i = start; i <= end; i++) {
      items = [
        ...items,
        {
          value: i,
          label: i,
        },
      ];
    }
    this.setState(
      {
        yearItems: items,
      },
      () => {
        // console.log('year', this.state.yearItems)
        this.formRef.current.setFieldsValue({
          tahun: new Date().getFullYear(),
        });
      }
    );
  };

  generateSelectAsync = (stateName, url, label, value, idxAnggota = -1) => {
    return this.state["loading" + stateName] ? (
      <Skeleton active paragraph={false} />
    ) : (
      <Select
        style={{ minWidth: 200 }}
        dropdownMatchSelectWidth={true}
        id={stateName}
        showSearch
        placeholder="pilih"
        optionFilterProp="children"
        allowClear
        onSearch={(val) => {
          let tempUrl = "";
          if (url.includes("?")) {
            tempUrl = url + "&search=" + val;
          } else {
            tempUrl = url + "?search=" + val;
          }
          this.getMasterList(stateName, tempUrl, false);
        }}
        onPopupScroll={(e) => {
          e.persist();
          let target = e.target;
          if (target.scrollTop + target.offsetHeight >= target.scrollHeight) {
            if (
              this.state["data" + stateName].next != undefined &&
              this.state["data" + stateName].next != null
            ) {
              this.getMasterList(
                stateName,
                this.state["data" + stateName].next,
                true
              );
            }
          }
        }}
        onChange={(val) => {
          if (stateName == "pegawai" || stateName == "jabatan_tugas") {
            let temp = [...this.state.anggota];
            temp[idxAnggota][stateName] = val;
            this.setState({
              anggota: temp,
            });
          }
        }}
      >
        {this.state["loadingSearch" + stateName] && (
          <Skeleton active paragraph={false} />
        )}
        {this.state["data" + stateName] != undefined &&
          this.state["data" + stateName] != null && (
            <>
              {this.state["data" + stateName].results != undefined
                ? this.state["data" + stateName].results.map((item) => (
                  <Select.Option value={item[value]}>
                    {item[label]}
                  </Select.Option>
                ))
                : this.state["data" + stateName].map((item) => (
                  <Select.Option value={item[value]}>
                    {item[label]}
                  </Select.Option>
                ))}
            </>
          )}
        {this.state["loadingNext" + stateName] && (
          <Skeleton active paragraph={false} />
        )}
      </Select>
    );
  };

  setFile(e, name, id) {
    this.setState(
      {
        [name + "_file"]: e.target.files[0],
      },
      () => {
        // console.log(this.state[name+"_file"])
        let params = new FormData();
        params.append("lampiran", this.state[name + "_file"], this.state[name + "_file"].name)
        this.doUploadLampiran(params, id);
      }
    );
  }

  showReview(row) {
    this.setState(
      {
        suratTugas: row,
        openReview: true,
      },
      () => {
      }
    );
  }

  showInsert(row) {
    let tempAnggota = [];
    if (row.anggota != undefined) {
      row.anggota.map((item, idx) => {
        tempAnggota = [...tempAnggota, {
          label: item.pegawai_nama == undefined ? "anggota->pegawai_nama" : item.pegawai_nama,
          value: item.pegawai,
        }];
      });
    }
    this.setState(
      {
        suratTugas: row,
        open: true,
        aksi: "tambah",
        idLama: "",
        method: "post",
        editingKey: "",
        anggota: tempAnggota,
        hariAnggota: row.anggota,
      },
      () => {
        this.formRef.current.resetFields();
        this.formRef.current.setFieldsValue({
          nomor_surat: row.surat_tugas_nomor
        });
      }
    );
  }

  showEdit = (record) => {
    this.formRef.current.resetFields();

    let tempData = this.state.dataSource;

    this.setState(
      {
        prevSource: tempData,
        editingKey: record.id,
      },
      () => {
        this.preview(record.id);
      }
    );
    // this.formRef.current.setFieldsValue({
    //   nama: '',
    //   ...record,
    // });
    // this.setState({
    //   editingKey: record.id
    // }, console.log('editingkey', record.id));
  };

  async preview(id) {
    this.setState({
      loadingData: true,
      errTitle: "",
      errMsg: "",
    });

    this.props
      .ajaxViewHandler("get", this.props.configClass.apiUrl.preview + id)
      .then(() => {
        // setTimeout(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          // this.formRef.current.setFieldsValue(data);
          for (var key in data) {
            const dateRegex = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/;
            const dateTimeRegex =
              /^[0-9]{4}\-[0-9]{2}\-[0-9]{2} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;
            if (dateRegex.test(data[key])) {
              //jika format tanggal
              this.formRef.current.setFieldsValue({
                [key + "_picker"]: moment(new Date(data[key]), "DD-MM-YYYY"),
                [key]: moment(new Date(data[key]), "DD-MM-YYYY"),
              });
            } else if (dateTimeRegex.test(data[key])) {
              //jika format tanggal dan jam
              this.formRef.current.setFieldsValue({
                [key + "_picker"]: moment(
                  new Date(data[key]),
                  "DD-MM-YYYY HH:mm:ss"
                ),
                [key]: moment(new Date(data[key]), "DD-MM-YYYY HH:mm:ss"),
              });
            } else {
              if (key == "body") {
                this.setState({
                  artikelString: data[key],
                });
                this.formRef.current.setFieldsValue({
                  content_editor: data[key],
                  body_editor: data[key],
                });
              } else if (key == "tag") {
                let temp = [];
                data[key].map((item) => {
                  temp = [
                    ...temp,
                    {
                      value: item.id,
                      label: item.nama,
                      key: item.id,
                    },
                  ];
                });
                this.formRef.current.setFieldsValue({
                  tags: temp,
                });
              } else if (key == "image" || key == "dokumen") {
                // let temp = [];
                // let tempGb = [];
                // data[key].map((item) => {
                //   temp = [
                //     ...temp,
                //     {
                //       id: item.id,
                //       name: item.name,
                //     },
                //   ];
                //   tempGb = [
                //     ...tempGb,
                //     {
                //       id: item.id,
                //       name: item.name,
                //       path: item.path,
                //     },
                //   ];
                // });
                // this.setState({
                //   gbr_post: temp,
                //   gambar_edit: tempGb,
                // });
              } else {
                this.formRef.current.setFieldsValue({
                  [key]: data[key],
                });
              }
            }

            // if (key == "icon") {
            //   this.setState({
            //     preview_gambar: data[key],
            //   });
            // }
          }
          message.destroy();
          this.setState(
            {
              aksi: "edit",
              method: "put",
              idLama: data.id + "/",
              loadingData: false,
            },
            () => {
              if (this.focusComponent != null && this.focusComponent != "") {
                document.getElementById(this.focusComponent).focus();
              }
            }
          );
        } else {
          const errTitle = "error preview data lama";
          this.setState({
            errTitle: errTitle,
            errMsg: JSON.stringify(data),
            loadingData: false,
          });
        }
        // }, 500);
      })
      .catch((response) => {
        this.setState({
          errTitle: "gagal get data",
          errMsg: JSON.stringify(response),
          loadingData: false,
        });
      });
  }

  // editableCell = (
  //   editing,
  //   dataIndex,
  //   title,
  //   inputType,
  //   record,
  //   index,
  //   children,
  //   ...restProps
  // ) => {
  //   const inputNode = <Input />;
  //   return (
  //     <td>
  //       {editing != undefined && editing ? (
  //         <Form.Item
  //           name={dataIndex}
  //           style={{
  //             margin: 0,
  //           }}
  //           rules={[
  //             {
  //               required: true,
  //               message: `Please Input ${title}!`,
  //             },
  //           ]}
  //         >
  //           {inputNode}
  //         </Form.Item>
  //       ) : (
  //         children
  //       )}
  //     </td>
  //   );
  // };

  componentDidMount() {
    this.getMasterList("template", configClass.apiUrl.template);
    this.getMasterList("disusun_oleh", configClass.apiUrl.disusun_oleh);
    this.getMasterList("disetujui_oleh", configClass.apiUrl.disetujui_oleh);
    this.getMasterList("mengetahui", configClass.apiUrl.mengetahui);
  }

  setVal(e, stateName) {
    this.setState({
      [stateName]: e.target.value,
    });
  }


  async postData(datas, id) {
    this.setState({
      loadingData: true,
      errTitle: "",
      errMsg: "",
      dataClone: null,
    });
    // message.loading({ content: 'Memproses...', key: 'toast' });

    this.props
      .ajaxHandler(
        "post",
        configClass.apiUrl.clone + id + "/clone_agenda/",
        datas,
        false,
        true
      )
      .then((res) => {
        // setTimeout(() => {
        this.setState({
          loadingData: false,
        });

        if (res.type == "SUCCESS_HANDLER") {
          const data = res.payload.data;
          this.setState({
            dataClone: data
          }, () => {
            let temp_params = [];
            data.agenda_pengawasan.map((item, idx) => {
              item.item_agenda.map((item_agenda, idx_agenda) => {
                // let temp_agenda = []
                Object.keys(data.schema).map((itemHeader, idxHeader) => {
                  temp_params = [...temp_params,
                  {
                    id: item_agenda.id,
                    schema: itemHeader,
                    value: item_agenda.schema[itemHeader]
                  }
                  ]
                });
              });
            });
            this.setState({
              agendas: temp_params
            });
          });
          // notification.success({
          //   message: "berhasil",
          //   description: "berhasil clone template.",
          //   placement: "bottomRight",
          // });
        } else {
          notification.error({
            message: "gagal",
            description: "gagal clone template.",
            placement: "bottomRight",
          });
          const errTitle = "gagal clone template";
          // console.log("tes", res);
          this.setState({
            errTitle: errTitle,
            errMsg:
              res.error.response.data.message != undefined
                ? JSON.stringify(res.error.response.data.message)
                : errTitle,
          });
        }
        // }, 0);
      })
      .catch((response) => {
        notification.error({
          message: "gagal",
          description: "gagal menyimpan.",
          placement: "bottomRight",
        });
        this.setState({
          loadingData: false,
          errTitle: "gagal menyimpan",
          errMsg: JSON.stringify(response),
        });
      });
  }


  handleKeyUp = (event) => {
    // Enter
    if (event.keyCode === 13) {
      this.formRef.current.submit();
    }
  };

  handleSubmit = (values) => {
    var params = {};
    for (var key in values) {
      if (values[key] == undefined) {
        params = { ...params, [key]: "" };
      } else {
        // datas.append(key, values[key].toString());
        params = { ...params, [key]: values[key].toString() };
      }
    }

    this.postData(params, this.props.match.params.id);
  };

  handleChangeHari(e, id, itemHeader) {
    let value = e.target.value;
    let temp = this.state.agendas;
    temp.map((item, idx) => {
      if (item.id == id && item.schema == itemHeader) {
        temp[idx].id = item.id;
        temp[idx].schema = item.schema;
        temp[idx].value = parseFloat(value);
      }
    });
    this.setState({
      agendas: temp
    });
  }

  handleSubmitFormAnggaran = (values) => {
    var params = {};
    for (var key in values) {
      if (values[key] == undefined) {
        params = { ...params, [key]: "" };
      } else {
        // datas.append(key, values[key].toString());
        params = { ...params, [key]: values[key].toString() };
      }
    }
    params = { ...params, agenda: this.state.agendas };
    // const rencana_kunjungan = [...this.state.supervisi, ...this.state.realisasi];
    // params = { ...params, rencana_kunjungan: rencana_kunjungan };
    // params = { ...params, surat_tugas: this.state.suratTugas.surat_tugas };
    // params = { ...params, anggota: this.state.hariAnggota };

    console.log(params)

    this.postDataAnggaran(params, this.props.match.params.id);
  }

  async postDataAnggaran(datas, id) {
    this.setState({
      loadingData: true,
      errTitle: "",
      errMsg: "",
    });
    // message.loading({ content: 'Memproses...', key: 'toast' });

    this.props
      .ajaxHandler(
        "post",
        configClass.apiUrl.clone + id + "/multi_schema/",
        datas,
        false,
        true
      )
      .then((res) => {
        // setTimeout(() => {
        this.setState({
          loadingData: false,
        });

        if (res.type == "SUCCESS_HANDLER") {
          const data = res.payload.data;
          this.setState({
            is_success: true
          });
          notification.success({
            message: "berhasil",
            description: "berhasil menyimpan.",
            placement: "bottomRight",
          });
        } else {
          notification.error({
            message: "gagal",
            description: "gagal menyimpan.",
            placement: "bottomRight",
          });
          const errTitle = "gagal menyimpan";
          // console.log("tes", res);
          this.setState({
            errTitle: errTitle,
            errMsg:
              res.error.response.data.message != undefined
                ? JSON.stringify(res.error.response.data.message)
                : errTitle,
          });
        }
        // }, 0);
      })
      .catch((response) => {
        notification.error({
          message: "gagal",
          description: "gagal menyimpan.",
          placement: "bottomRight",
        });
        this.setState({
          loadingData: false,
          errTitle: "gagal menyimpan",
          errMsg: JSON.stringify(response),
        });
      });
  }

  render() {
    if (this.state.is_success) {
      return <Redirect to="/admin/persiapan/anggaran-waktu-pelaksanaan" />
    }
    return (
      <>
        {/*<!-- BEGIN: Content-->*/}
        <Breadcrumb>
          {this.props.breadCrumb.map((item, idx) => (
            <Breadcrumb.Item key={"bread" + idx}>{item.label}</Breadcrumb.Item>
          ))}
        </Breadcrumb>
        <div className="card">
          <Row className="isi">
            <Col span={24}>
              <Card
                className="konten"
                title={
                  <Row>
                    <Col lg={{ span: 20 }} xs={{ span: 24 }}>
                      <span className="konten-judul">
                        {this.props.configClass.name}
                      </span>
                    </Col>
                    <Col
                      lg={{ span: 4 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      style={{ textAlign: "right" }}
                    >
                      <Space>
                        <Button
                          loading={this.state.loadingData}
                          type="default"
                          size="small"
                          onClick={() => this.getData()}
                        >
                          <RedoOutlined />
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                }
              >

                {this.state.errMsg != '' &&
                  <Alert message={this.state.errMsg} type="error" showIcon />
                }

                <Form
                  name="finput"
                  // onKeyUp={this.handleKeyUp}
                  onFinish={this.handleSubmit}
                  autoComplete="off"
                  ref={this.formRef}
                  layout="vertical"
                >
                  <Row gutter={[16, 16]}>
                    <Col span={11}>
                      <Form.Item
                        label="NOMOR SURAT"
                        name="nomor_surat"
                      >
                        <Input placeholder="input nomor surat" />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        label="TEMPLATE AGENDA"
                        name="template"
                      >
                        {this.generateSelectAsync(
                          "template",
                          configClass.apiUrl.template,
                          "nama",
                          "id"
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item label=" ">
                        <Button
                          type="primary"
                          success
                          htmlType="submit"
                        >
                          Build
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
                {this.state.dataClone != null &&
                  <Form
                    layout="vertical"
                    onFinish={this.handleSubmitFormAnggaran}
                    ref={this.formRefAnggaran}
                  >
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <div style={{ overflowX: "auto" }}>
                          <table
                            // border={1}
                            cellSpacing={3}
                            style={{
                              width: "100%"
                            }}
                          // style={{
                          //   overflowX: "scroll",
                          //   maxWidth: "-moz-fit-content",
                          //   maxWidth: "fit-content",
                          //   margin: "0 auto",
                          //   overflowX: "auto",
                          //   whiteSpace: "nowrap"
                          // }}
                          >
                            <tr>
                              <td></td>
                              {Object.keys(this.state.dataClone.schema).map((itemHeader, idxHeader) => (
                                <td style={{ minWidth: 100, textAlign: "center", fontWeight: "bold" }}>{itemHeader.replaceAll("_", " ").toUpperCase()}</td>
                              ))}
                            </tr>
                            {this.state.dataClone.agenda_pengawasan.map((itemRow, idxRow) => (
                              <>
                                <tr>
                                  <td
                                    colSpan={Object.keys(this.state.dataClone.schema).length + 1}
                                    style={{
                                      fontWeight: "bold",
                                      textTransform: "uppercase",
                                      whiteSpace: "nowrap"
                                    }}
                                  >
                                    {itemRow.nama}
                                  </td>
                                </tr>
                                {itemRow.item_agenda.map((itemSubRow, idxSubRow) => (
                                  <tr>
                                    <td style={{ whiteSpace: "nowrap" }}>{itemSubRow.nama}</td>
                                    {Object.keys(this.state.dataClone.schema).map((itemHeader, idxHeader) => (
                                      <>
                                        <td>
                                          <Input
                                            defaultValue={itemSubRow.schema[itemHeader]}
                                            placeholder="input hari"
                                            onChange={(e) => this.handleChangeHari(e, itemSubRow.id, itemHeader)}
                                          />
                                        </td>
                                      </>
                                    ))}
                                  </tr>
                                ))}
                              </>
                            ))}
                          </table>
                        </div>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          name="tanggal_anggaran"
                          label="TANGGAL ANGGARAN WAKTU PENUGASAN"
                        >
                          <DatePicker
                            placeholder="dd-mm-yyyy"
                            format="DD-MM-YYYY"
                            style={{ width: "100%" }}
                            onChange={(date, dateString) => {

                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          name="disusun_oleh"
                          label="DISUSUN OLEH"
                        >
                          {this.generateSelectAsync(
                            "disusun_oleh",
                            configClass.apiUrl.disusun_oleh,
                            "nama",
                            "id"
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          name="disetujui_oleh"
                          label="DISETUJUI OLEH"
                        >
                          {this.generateSelectAsync(
                            "disetujui_oleh",
                            configClass.apiUrl.disetujui_oleh,
                            "nama",
                            "id"
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          name="mengetahui"
                          label="MENGETAHUI"
                        >
                          {this.generateSelectAsync(
                            "mengetahui",
                            configClass.apiUrl.mengetahui,
                            "nama",
                            "id"
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item>
                      <Space>
                        {/* <Popconfirm
                          title="yakin menyimpan data?"
                          // onConfirm={() => this.formRefAnggaran.current.submit()}
                          okText="Ya"
                          cancelText="Tidak"
                          okButtonProps={{
                            type: "primary",
                            danger: true,
                          }}
                          cancelButtonProps={{
                            type: "primary",
                          }}
                        > */}
                        <Button htmlType="submit" type="primary" title="simpan">
                          <SaveOutlined /> Simpan
                        </Button>
                        {/* </Popconfirm> */}
                        <Link to="/admin/persiapan/anggaran-waktu-pelaksanaan/">
                          <Button
                            htmlType="button"
                            type="primary"
                            danger
                            title="batalkan"
                          >
                            <CloseSquareOutlined /> Batal
                          </Button>
                        </Link>
                      </Space>
                    </Form.Item>
                  </Form>
                }
              </Card>
            </Col>
          </Row>
        </div>

      </>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  setConfig,
  ajaxHandler,
  ajaxViewHandler,
})(withRouter(FormAnggaranWaktuPelaksanaan));
