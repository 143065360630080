export const area_pengawasan_wajib = {
  router: [
    {
      name: "AREA PENGAWASAN WAJI (Mandatory)",
      key: "area_pengawasan_wajib",
      path: "/admin/ppbr/area-pengawasan-wajib",
      apiUrl: {
        data: "ppbr/api/area/pengawasan/",
        preview: "ppbr/api/area/pengawasan/",
      },
      breadCrumb: [
        {
          label: "PPBR",
          link: "#",
          active: false,
        },
        {
          label: "AREA PENGAWASAN WAJIB (MANDATORY)",
          link: "#",
          active: true,
        },
      ],
      component: "datatable1",
    },
  ],
  model: [
    {
      name: "id",
      isPk: true,
      isForm: false,
      isTable: false,
    },
    {
      name: "judul",
      label: "Judul Kegiatan",
      type: "text",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "alasan",
      label: "Alasan Wajib",
      type: "textarea",
      tableTipe: "deskripsi",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "keterangan",
      label: "Keterangan",
      type: "textarea",
      tableTipe: "deskripsi",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "mulai_dari",
      label: "Mulai Dari",
      type: "date",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "sampai_dengan",
      label: "Sampai Dengan",
      type: "date",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },

  ],
};
