export const kode_rekomendasi = {
  router: [
    {
      name: "KODE REKOMENDASI",
      key: "kode_rekomendasi",
      path: "/admin/master/kode-rekomendasi",
      apiUrl: {
        data: "master/api/kode/rekomendasi/",
        preview: "master/api/kode/rekomendasi/",
      },
      breadCrumb: [
        {
          label: "MASTER",
          link: "#",
          active: false,
        },
        {
          label: "KODE REKOMENDASI",
          link: "#",
          active: true,
        },
      ],
      component: "datatable1",
    },
  ],
  model: [
    {
      name: "id",
      isPk: true,
      isForm: false,
      isTable: false,
    },
     {
      name: "kode",
      label: "Kode",
      type: "text",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
    {
      name: "nama",
      label: "Nama",
      type: "text",
      validation: {
        insert: [
          {
            required: true,
            message: " harus diisi",
          },
        ],
      },
      isForm: true,
      isTable: true,
    },
  ],
};
